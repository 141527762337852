<template>
    <div id="app">
        <div class="loader-backdrop" :class="{ loading: isLoadingRoute || isInitialLoad }"></div>

        <Header />

        <transition name="slide">
            <Loader v-if="isLoadingRoute || isInitialLoad" :isInitialLoad="isInitialLoad"></Loader>
        </transition>

        <div id="inner-app">
            <router-view></router-view>
            <Footer v-if="!isLoadingRoute" />
        </div>

        <Customcursor />
    </div>
</template>

<script>
import Header from '@/components/layout/Header.vue';
import Footer from '@/components/layout/Footer.vue';
import Customcursor from '@/components/Customcursor.vue';
import Loader from '@/components/loader.vue';
import EventBus from './eventbus';
import preload from './store/preload';

import { gsap, ScrollTrigger } from 'gsap/all';
import ASScroll from '@ashthornton/asscroll';

export default {
    name: 'App',

    metaInfo: {
        title: '%s | Bisson Fortin',
    },

    components: {
        Header,
        Footer,
        Customcursor,
        Loader,
    },

    computed: {
        isDataReady() {
            return this.$store.state.isDataReady;
        },
        isInitialLoad() {
            preload();
            return this.$store.state.isInitialLoad;
        },
        isLoadingRoute() {
            return this.$store.state.isLoadingRoute;
        },
    },

    data() {
        return {
            asscroll: null,
        };
    },

    updated() {
        this.asscroll.scrollTo(0, false);
    },

    mounted() {
        gsap.registerPlugin(ScrollTrigger);
        let self = this;
        this.asscroll = new ASScroll({
            customScrollbar: true,
        });

        ScrollTrigger.defaults({
            scroller: '#inner-app',
        });

        ScrollTrigger.scrollerProxy('#inner-app', {
            scrollTop(value) {
                return arguments.length ? self.asscroll.scrollTo(value) : -self.asscroll.smoothScrollPos;
            },
            getBoundingClientRect() {
                return {
                    top: 0,
                    left: 0,
                    width: window.innerWidth,
                    height: window.innerHeight,
                };
            },
        });

        self.asscroll.on('raf', ScrollTrigger.update);
        self.asscroll.on('scroll', ScrollTrigger.update);
        self.asscroll.on('scroll', headeranim);

        let lastScroll = 0;
        const elementHeader = document.querySelector('header');

        function headeranim() {
            if (!elementHeader.classList.contains('header--mobile-opened')) {
                const currentScroll = self.asscroll.scrollPos;

                if (currentScroll < lastScroll && !elementHeader.classList.contains('header--scrolled')) {
                    elementHeader.classList.add('header--scrolled');
                } else if (currentScroll > lastScroll && elementHeader.classList.contains('header--scrolled')) {
                    elementHeader.classList.remove('header--scrolled');
                }

                lastScroll = currentScroll;

                if (lastScroll >= 0) {
                    elementHeader.classList.remove('header--scrolled');
                    elementHeader.classList.add('header--top');
                } else elementHeader.classList.remove('header--top');
            }

            if (lastScroll >= 0) {
                elementHeader.classList.remove('header--scrolled');
                elementHeader.classList.add('header--top');
            } else {
                elementHeader.classList.remove('header--top');
            }

            const bgDark = document.querySelector('.bg-dark');
            if (bgDark) {
                if (!elementHeader.classList.contains('header--scrolled') && bgDark.classList.contains('bg-dark-active')) {
                    gsap.to('.language-switcher', { backgroundColor: '#353D35' });
                    gsap.to('.language-switcher .inner', { color: '#ffffff' });
                } else if (elementHeader.classList.contains('header--scrolled') && bgDark.classList.contains('bg-dark-active')) {
                    gsap.to('.language-switcher', { backgroundColor: '#ffffff' });
                    gsap.to('.language-switcher .inner', { color: '#353D35' });
                }
            }
        }

        ScrollTrigger.addEventListener('refresh', () => self.asscroll.onResize());
        ScrollTrigger.refresh();

        self.asscroll.enable(false, true, document.querySelector('#inner-app'));

        EventBus.$on('ASSCROLL_TO', function(pos) {
            self.asscroll.scrollTo(pos);
        });

        setTimeout(() => {
            this.$store.commit('SET_INITIAL_LOAD', false);
            ScrollTrigger.refresh();
        }, 500);

        //Cookiefirst scroll hack
        if (window.location.href.includes('bissonfortin.ca')) {
            const cookieFirstObserver = new MutationObserver((mutationsList) => {
                for (const mutation of mutationsList) {
                    // Check if nodes were added to the body
                    if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
                        // Loop through the added nodes and check if any of them is the element you're interested in
                        for (const node of mutation.addedNodes) {
                            if (node.nodeType === Node.ELEMENT_NODE && node.tagName === 'DIALOG') {
                                // The element is added to the body
                                self.asscroll.disable();
                                return; // Exit the loop once we find the element we're looking for
                            }
                        }
                    }

                    // Check if nodes were removed from the body
                    else if (mutation.type === 'childList' && mutation.removedNodes.length > 0) {
                        // Loop through the added nodes and check if any of them is the element you're interested in
                        for (const node of mutation.removedNodes) {
                            if (node.nodeType === Node.ELEMENT_NODE && node.tagName === 'DIALOG') {
                                // The element is added to the body
                                self.asscroll.enable();
                                return; // Exit the loop once we find the element we're looking for
                            }
                        }
                    }
                }
            });

            // Start observing the body for changes
            cookieFirstObserver.observe(document.body, { childList: true, subtree: true });
        }
    },
};
</script>

<style lang="scss">
@import 'assets/styles/style.scss';

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s ease-in-out;
}

.slide-enter,
.slide-leave-to {
    transform: translateY(100%);
}

.slide-enter-to,
.slide-leave {
    transform: translateY(0);
}
</style>
