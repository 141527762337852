<template>
    <main class="p-news" v-if="pageContent">
        <section class="simple-hero">
            <p class="simple-hero__suptitle" v-html="pageContent.fields.hero_suptitle"></p>
            <h1 v-html="pageContent.title"></h1>
            <div class="simple-hero__subtitle-cols">
                <div class="simple-hero__subtitle-cols__col"></div>
                <div class="simple-hero__subtitle-cols__col" v-html="pageContent.fields.hero_description"></div>
            </div>
            <div class="simple-hero__footer">
                <p class="simple-hero__footer-date">
                    {{ pageContent.fields.hero_date }}
                </p>
                <p>
                    <span class="simple-hero__footer-writingby">Écrit par </span>
                    <span class="simple-hero__footer-author">{{ pageContent.fields.hero_author }}</span>
                    <span class="simple-hero__footer-readingtime">{{ pageContent.fields.hero_time }}</span>
                </p>
            </div>
        </section>
        <section
            v-for="(block, index) in pageContent.fields.blocks"
            :key="index"
            class="container block"
            :class="{
                first: index === 0,
                fullwidth: block.matrix_element_type === 'image_fullwidth',
                paddingcustom:
                    block.matrix_element_type === 'image_left_title_description' ||
                    block.matrix_element_type === 'image' ||
                    block.matrix_element_type === 'title_left_description' ||
                    block.matrix_element_type === 'centeredtext',
            }"
        >
            <div v-if="block.matrix_element_type === 'image' && block.image" class="image__centered">
                <div class="rect"></div>
                <img
                    :src="block.image.url"
                    :alt="
                        block.alt ||
                            block.image.url
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
            </div>

            <div v-else-if="block.matrix_element_type === 'image_fullwidth' && block.image" class="image-container">
                <img
                    :src="block.image.url"
                    :alt="
                        block.alt ||
                            block.image.url
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
            </div>

            <div v-else-if="block.matrix_element_type === 'text'" class="text">
                <div class="text__content textanim" v-html="block.text"></div>
            </div>

            <div v-else-if="block.matrix_element_type === 'centeredtext'" class="text centered textanim">
                <div class="rect"></div>
                <h2 v-html="block.title"></h2>
                <div v-html="block.text"></div>
            </div>

            <div v-else-if="block.image_left && block.image_right" class="two-cols">
                <img
                    :src="block.image_left.url"
                    :alt="
                        block.alt ||
                            block.image_left.url
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
                <img
                    :src="block.image_right.url"
                    :alt="
                        block.alt ||
                            block.image_right.url
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
            </div>
            <div v-else-if="block.matrix_element_type === 'image_left_title_description'" class="image-left-title-description">
                <img
                    :src="block.image_left.url"
                    :alt="
                        block.alt ||
                            block.image_left.url
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
                <div class="content-title-description">
                    <div class="rect"></div>
                    <h2 v-html="block.title"></h2>
                    <div v-html="block.description"></div>
                </div>
            </div>
            <div v-else-if="block.matrix_element_type === 'title_left_description'" class="title-left-description">
                <h2 v-html="block.title"></h2>
                <div v-html="block.description"></div>
            </div>
        </section>
        <section class="visit">
            <a href="/"
                >Visitez <br />
                <span>notre </span><span>site </span><span>web</span></a
            >
        </section>
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import BaseView from './BaseView';

export default {
    name: 'News',

    computed: {
        ...mapGetters(['pageContent', 'isLoadingRoute']),
    },

    watch: {
        isLoadingRoute(newValue, oldValue) {
            this.initAnimations();
        },
    },

    methods: {
        initAnimations() {
            gsap.fromTo(
                '.simple-hero',
                {
                    y: 200,
                    opacity: 0,
                },
                {
                    y: 0,
                    opacity: 1,
                    duration: 0.7,
                    delay: 1.2,
                    ease: 'Power1.easeOut',
                }
            );

            gsap.from('#Shape-Copy', {
                x: 90,
                opacity: 0,
                duration: 1,
                ease: 'Power1.easeOut',
                scrollTrigger: {
                    trigger: '#Shape-Copy',
                    start: 'center bottom',
                },
            });

            gsap.from('#Path-Copy', {
                x: 90,
                opacity: 0,
                duration: 1.6,
                ease: 'Power1.easeOut',
                scrollTrigger: {
                    trigger: '#Path-Copy',
                    start: 'center bottom',
                },
            });

            gsap.from('#Rectangle-Copy-3', {
                x: 90,
                opacity: 0,
                duration: 2,
                ease: 'Power1.easeOut',
                scrollTrigger: {
                    trigger: '#Rectangle-Copy-3',
                    start: 'center bottom',
                },
            });

            ScrollTrigger.refresh();

            const containers = gsap.utils.toArray('.container');
            containers.forEach((container) => {
                gsap.from(container, {
                    y: 200,
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: container,
                        start: 'top bottom',
                        end: 'bottom +50',
                    },
                });

                gsap.from(container, {
                    opacity: 0,
                    duration: 0.6,
                    scrollTrigger: {
                        trigger: container,
                        start: 'top bottom',
                        end: 'bottom bottom',
                    },
                });
            });

            const imageLeftTextRight = gsap.utils.toArray('.image-left-title-description');

            if (imageLeftTextRight.length > 0) {
                imageLeftTextRight.forEach((container) => {
                    gsap.to(container.querySelector('img'), {
                        y: 40,
                        ease: 'Power1.easeOut',
                        scrollTrigger: {
                            trigger: container,
                            start: 'top bottom',
                            scrub: true,
                        },
                    });

                    gsap.to(container.querySelector('.content-title-description'), {
                        y: -30,
                        ease: 'Power1.easeOut',
                        scrollTrigger: {
                            trigger: container,
                            start: 'top bottom',
                            scrub: true,
                        },
                    });
                });
            }
        },
    },

    extends: BaseView,
};
</script>
