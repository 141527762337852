var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageContent)?_c('main',{staticClass:"p-news"},[_c('section',{staticClass:"simple-hero"},[_c('p',{staticClass:"simple-hero__suptitle",domProps:{"innerHTML":_vm._s(_vm.pageContent.fields.hero_suptitle)}}),_c('h1',{domProps:{"innerHTML":_vm._s(_vm.pageContent.title)}}),_c('div',{staticClass:"simple-hero__subtitle-cols"},[_c('div',{staticClass:"simple-hero__subtitle-cols__col"}),_c('div',{staticClass:"simple-hero__subtitle-cols__col",domProps:{"innerHTML":_vm._s(_vm.pageContent.fields.hero_description)}})]),_c('div',{staticClass:"simple-hero__footer"},[_c('p',{staticClass:"simple-hero__footer-date"},[_vm._v(" "+_vm._s(_vm.pageContent.fields.hero_date)+" ")]),_c('p',[_c('span',{staticClass:"simple-hero__footer-writingby"},[_vm._v("Écrit par ")]),_c('span',{staticClass:"simple-hero__footer-author"},[_vm._v(_vm._s(_vm.pageContent.fields.hero_author))]),_c('span',{staticClass:"simple-hero__footer-readingtime"},[_vm._v(_vm._s(_vm.pageContent.fields.hero_time))])])])]),_vm._l((_vm.pageContent.fields.blocks),function(block,index){return _c('section',{key:index,staticClass:"container block",class:{
            first: index === 0,
            fullwidth: block.matrix_element_type === 'image_fullwidth',
            paddingcustom:
                block.matrix_element_type === 'image_left_title_description' ||
                block.matrix_element_type === 'image' ||
                block.matrix_element_type === 'title_left_description' ||
                block.matrix_element_type === 'centeredtext',
        }},[(block.matrix_element_type === 'image' && block.image)?_c('div',{staticClass:"image__centered"},[_c('div',{staticClass:"rect"}),_c('img',{attrs:{"src":block.image.url,"alt":block.alt ||
                        block.image.url
                            .split('/')
                            .pop()
                            .split('.')[0]}})]):(block.matrix_element_type === 'image_fullwidth' && block.image)?_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":block.image.url,"alt":block.alt ||
                        block.image.url
                            .split('/')
                            .pop()
                            .split('.')[0]}})]):(block.matrix_element_type === 'text')?_c('div',{staticClass:"text"},[_c('div',{staticClass:"text__content textanim",domProps:{"innerHTML":_vm._s(block.text)}})]):(block.matrix_element_type === 'centeredtext')?_c('div',{staticClass:"text centered textanim"},[_c('div',{staticClass:"rect"}),_c('h2',{domProps:{"innerHTML":_vm._s(block.title)}}),_c('div',{domProps:{"innerHTML":_vm._s(block.text)}})]):(block.image_left && block.image_right)?_c('div',{staticClass:"two-cols"},[_c('img',{attrs:{"src":block.image_left.url,"alt":block.alt ||
                        block.image_left.url
                            .split('/')
                            .pop()
                            .split('.')[0]}}),_c('img',{attrs:{"src":block.image_right.url,"alt":block.alt ||
                        block.image_right.url
                            .split('/')
                            .pop()
                            .split('.')[0]}})]):(block.matrix_element_type === 'image_left_title_description')?_c('div',{staticClass:"image-left-title-description"},[_c('img',{attrs:{"src":block.image_left.url,"alt":block.alt ||
                        block.image_left.url
                            .split('/')
                            .pop()
                            .split('.')[0]}}),_c('div',{staticClass:"content-title-description"},[_c('div',{staticClass:"rect"}),_c('h2',{domProps:{"innerHTML":_vm._s(block.title)}}),_c('div',{domProps:{"innerHTML":_vm._s(block.description)}})])]):(block.matrix_element_type === 'title_left_description')?_c('div',{staticClass:"title-left-description"},[_c('h2',{domProps:{"innerHTML":_vm._s(block.title)}}),_c('div',{domProps:{"innerHTML":_vm._s(block.description)}})]):_vm._e()])}),_vm._m(0)],2):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"visit"},[_c('a',{attrs:{"href":"/"}},[_vm._v("Visitez "),_c('br'),_c('span',[_vm._v("notre ")]),_c('span',[_vm._v("site ")]),_c('span',[_vm._v("web")])])])}]

export { render, staticRenderFns }