<template>
    <main class="p-404">
        <section class="simple-hero">
            <h1>{{ $t('404.title') }}</h1>
            <div class="simple-hero__subtitle">
                {{ $t('404.intro') }}
                <router-link :to="$t('nav.home.slug')" :title="$t('404.cta')">{{ $t('404.cta') }}</router-link>
            </div>
        </section>
    </main>
</template>

<script>
import BaseView from './BaseView';

export default {
    name: 'p404',

    extends: BaseView,
};
</script>
