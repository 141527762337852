<template>
    <div class="job">
        <Cta :type="'more'" :forceStatus="openedJobId == job.id" @click.prevent.native="seeMore()"></Cta>
        <div class="job__main-info">
            <div class="job__main-info__top">
                <div class="job__main-info__title">{{ job.title }}</div>
                <Cta :type="'outline'" :ctaText="$t('careers.apply')" :ctaUrl="mailTo"></Cta>
            </div>
            <div class="job__main-info__line"></div>
            <div class="job__main-info__type">
                <div>
                    <div class="job__main-info__type__label">{{ $t('careers.typeLabel') }}</div>
                    {{ $t(`careers.type.${job.fields.type}`) }}
                </div>
                <div>
                    <div class="job__main-info__type__label">{{ $t('careers.dateLabel') }}</div>
                    {{ new Date(job.modifiedDate.replace(/-/g, "/")) | dateFormat('DD MMMM YYYY', dateFormatConfig) }}
                </div>
            </div>
            <div class="job__main-info__desc" v-html="job.fields.shortdesc"></div>
        </div>
        <transition-expand>
            <div class="job__more-info" v-if="openedJobId == job.id">
                <div class="job__more-info__hours">
                    <div v-if="job.fields.hours">
                        <svg height="20" viewBox="0 0 20 20" width="20" xmlns="http://www.w3.org/2000/svg">
                            <g fill="#353d35">
                                <path
                                    d="m10 .01339286c-2.66517857 0-5.16964286 1.04017857-7.05357143 2.92410714-1.88392857 1.88839286-2.92410714 4.39285714-2.92410714 7.0625 0 2.6696429 1.04017857 5.1741071 2.92410714 7.0625s4.38839286 2.9241071 7.05357143 2.9241071c2.6651786 0 5.1696429-1.0401785 7.0535714-2.9241071 1.8839286-1.8883929 2.9241072-4.3928571 2.9241072-7.0625 0-2.66964286-1.0401786-5.17410714-2.9241072-7.0625-1.8839285-1.88392857-4.3883928-2.92410714-7.0535714-2.92410714zm0 19.16964284c-5.05803571 0-9.17410714-4.1205357-9.17410714-9.1830357s4.11607143-9.18303571 9.17410714-9.18303571c5.0580357 0 9.1741071 4.12053571 9.1741071 9.18303571s-4.1160714 9.1830357-9.1741071 9.1830357z"
                                />
                                <path
                                    d="m15.3035714 9.34375-5.46428569.5-2.50446428-2.50892857c-.15625-.15625-.41071429-.15625-.56696429 0s-.15625.41071428 0 .56696428l2.63839286 2.63839289c.07589286.0758928.17857143.1160714.28571429.1160714h.03571428l5.65178573-.5178571c.21875-.0223215.3839286-.21428576.3616071-.43750004-.0178571-.21428572-.2142857-.375-.4375-.35714286z"
                                />
                            </g>
                        </svg>
                        {{ job.fields.hours }}
                    </div>
                </div>
                <div class="job__more-info__mission" v-if="job.fields.main_mission">
                    <div class="job__more-info__label">{{ $t('careers.missionLabel') }}</div>
                    <div v-html="job.fields.main_mission"></div>
                </div>
                <div class="job__more-info__offer" v-if="job.fields.offer_definition">
                    <div class="job__more-info__label">{{ $t('careers.offerLabel') }}</div>
                    <div v-html="job.fields.offer_definition"></div>
                </div>
                <div class="job__more-info__dates" v-if="job.fields.contract_dates">
                    <div class="job__more-info__label">{{ $t('careers.datesLabel') }}</div>
                    <div v-html="job.fields.contract_dates"></div>
                </div>
            </div>
        </transition-expand>
    </div>
</template>

<script>
import Vue from 'vue';
import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';

import Cta from '@/components/cta.vue';
import TransitionExpand from '@/components/transitionExpand.vue';

import { ScrollTrigger } from 'gsap/ScrollTrigger';

Vue.use(VueFilterDateFormat);

export default {
    name: 'Job',

    components: {
        Cta,
        TransitionExpand,
    },

    props: {
        job: { type: Object },
    },

    data() {
        return {
            seeMoreInfos: false,
        };
    },

    computed: {
        mailTo() {
            return `mailto:recrutement@bissonfortin.ca?subject=${this.$t('careers.mailtoSubject')}%20%22${encodeURI(this.job.title)}%22`;
        },

        dateFormatConfig() {
            if (this.$i18n.locale == 'fr') {
                return {
                    monthNames: ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre'],
                };
            } else {
                return {
                    monthNames: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
                };
            }
        },

        openedJobId() {
            return this.$store.state.openedJobId;
        },
    },

    methods: {
        seeMore() {
            if (this.openedJobId !== this.job.id) {
                this.$store.commit('SET_OPENED_JOB_ID', this.job.id);
            } else {
                this.$store.commit('SET_OPENED_JOB_ID', '');
            }

            setTimeout(() => {
                ScrollTrigger.refresh(true);
            }, 1000);

            this.seeMoreInfos = !this.seeMoreInfos;
        },
    },
};
</script>
