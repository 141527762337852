<template>
    <div class="grid">
        <router-link
            class="project cursor-cta cursor-discover"
            v-for="(project, index) in projects"
            :to="project.name"
            :key="index"
            :data-size="getSize(index)"
            :class="{ 'alt-disposition': projects.length === 6 }"
        >
            <div class="main-image">
                <img
                    :src="getImageThumbnail(project, index)"
                    :alt="
                        project.fields.alt ||
                            getImageThumbnail(project, index)
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
            </div>

            <span class="project__overlay"></span>
            <span class="project__plus">
                <svg height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" fill-rule="evenodd" stroke="#101010">
                        <path d="m5 0v10" />
                        <path d="m10 5h-10" />
                    </g>
                </svg>
            </span>
            <div class="project__info">
                <span class="project__name">{{ project.title }}</span>
                <span class="project__date">{{ project.fields.date }}</span>
            </div>
        </router-link>
    </div>
</template>

<script>
export default {
    name: 'Masonry',

    props: {
        projects: { type: Array },
    },

    methods: {
        getSize(index) {
            if (this.projects.length === 4) {
                return index % 2 == 0 ? 'large' : 'small';
            } else {
                if (index < 2) {
                    return index % 2 == 0 ? 'small' : 'large';
                }
                return index % 2 == 0 ? 'large' : 'small';
            }
        },

        getImageThumbnail(project, index) {
            const size = this.getSize(index);
            if (size == 'large' && project.fields.img_thumbnail_featured) return project.fields.img_thumbnail_featured?.url;
            else if (project.fields.img_thumbnail_featured_vertical) return project.fields.img_thumbnail_featured_vertical.url;
            else if (project.fields.img_thumbnail_featured) return project.fields.img_thumbnail_featured?.url;
            else return project.fields.img_hero?.url;
        },

        goToProject(project) {
            this.$router.push(project.name);
        },
    },
};
</script>
