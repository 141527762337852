import Vue              from "vue";
import Router           from "vue-router";
import Store            from './store';

import Home             from "./views/Home";
import Project          from "./views/Project";
import ProjectsLanding  from "./views/ProjectsLanding";
import Blank  from "./views/Blank";
import About            from "./views/About";
import Careers          from "./views/Careers";
import Contact          from "./views/Contact";
import News             from "./views/News";
import Identity         from "./views/Identity";
import p404             from "./views/p404";

Vue.use(Router);

const routes = [
    {
        path: "/",
        component: Home
    },
    {
        path: "/nos-projets",
        component: ProjectsLanding,
        meta: {
            footerColorClass: 'bg-light'
        }
    },
    {
        path: "/nos-projets/:slug",
        component: Project,
        meta: {
            altMenu: true,
            noPageTransition: true
        }
    },
    {
        path: "/a-propos",
        component: About,
        meta: {
            footerColorClass: 'bg-light'
        }
    },
    {
        path: "/carrieres",
        component: Careers,
        meta: {
            footerColorClass: 'bg-dark'
        }
    },
    {
        path: "/contact",
        component: Contact,
        meta: {
            footerColorClass: 'bg-dark'
        }
    },
    {
        path: "/nouvelle-identite",
        component: Identity,
        meta: {
            noMenu: true,
            noFooter: true
        }
    },
    {
        path: "/nouvelle-identite",
        component: Identity,
        meta: {
            noMenu: true,
            noFooter: true
        }
    },
    {
        path: "/nouvelles/:slug",
        component: News
    },
    {
        path: "/politique-de-confidentialite",
        component: Blank,
        meta: {
            footerColorClass: 'bg-light'
        }
    },


    // Anglais
    {
        path: "/en",
        component: Home,
    },
    {
        path: "/en/our-projects",
        component: ProjectsLanding,
        meta: {
            footerColorClass: 'bg-light'
        }
    },
    {
        path: "/en/our-projects/:slug",
        component: Project,
        meta: {
            altMenu: true,
            noPageTransition: true
        }
    },
    {
        path: "/en/about",
        component: About,
        meta: {
            footerColorClass: 'bg-light'
        }
    },
    {
        path: "/en/careers",
        component: Careers,
        meta: {
            footerColorClass: 'bg-dark'
        }
    },
    {
        path: "/en/contact",
        component: Contact,
        meta: {
            footerColorClass: 'bg-dark'
        }
    },
    {
        path: "/en/news/:slug",
        component: News
    },
    {
        path: "/en/privacy-policy",
        component: Blank,
        meta: {
            footerColorClass: 'bg-light'
        }
    },

    // 404
    {
        path: "/404",
        component: p404
    },
    {
        path: "/en/404",
        component: p404
    },
    {
        path: '/en/*',
        redirect: '/en/404'
    },
    {
        path: '*',
        redirect: '/404'
    }
];

const router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    scrollBehavior (to, from, savedPosition) {
        return new Promise((resolve, reject) => {
            setTimeout(() => {
                resolve({ x: 0, y: 0 })
            }, 200)
        })
    }
});

router.beforeEach(async (to, from, next) => {
    Store.commit('SET_DATA_READY', false);
    Store.dispatch('getPageContents', to.path);
    if (!from.meta.noPageTransition || !to.meta.noPageTransition) Store.commit('SET_LOADING_ROUTE', true);
    if (from.meta.noPageTransition && to.meta.noPageTransition) {
        Store.commit('SET_LOADING_ROUTE_PROJECT', true);
        Store.commit('SET_PROJECT_TRANSITION', true);
    }
    next();
});

export default router;
