<template>
    <footer :class="footerColorClass" v-if="!altMenu && !noFooter">
        <Cta :type="'outline'" :ctaText="$t('footer.ctaContactUs')" :ctaUrl="$t('nav.contact.slug')"></Cta>
        <div class="footer-content">
            <div class="footer-content__above">
                <div class="footer-content__above__addresses">
                    <p>{{ $t('footer.address1') }}</p>
                    <p>{{ $t('footer.address2') }}</p>
                </div>
                <div class="footer-content__above__shapes">
                    <span class="shape-triangle"></span>
                    <span class="shape-circle"></span>
                    <span class="shape-square"></span>
                </div>
            </div>
            <span class="footer-content__line"></span>
            <div class="footer-content__below">
                <div class="footer-content__below__copyright">{{ $t('footer.copyright') }} @{{ currentYear }}</div>
                <div class="footer-content__below__links">
                    <a class="footer-content__below__link" target="_blank" rel="noopener noreferrer" :href="$t('socialLinks.linkedIn')">LinkedIn</a>
                    <a class="footer-content__below__link" target="_blank" rel="noopener noreferrer" :href="$t('socialLinks.facebook')">Facebook</a>
                    <a class="footer-content__below__link" target="_blank" rel="noopener noreferrer" :href="$t('socialLinks.instagram')">Instagram</a>
                    <a class="footer-content__below__agencylink" target="_blank" rel="noopener noreferrer" href="https://www.leeroy.ca">{{ $t('footer.agency') }}</a>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import Cta from '@/components/cta.vue';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default {
    name: 'Footer',
    components: {
        Cta,
    },

    computed: {
        currentYear() {
            return new Date().getFullYear();
        },

        altMenu() {
            return this.$route.meta.altMenu;
        },

        footerColorClass() {
            return this.$route.meta.footerColorClass;
        },
        noFooter(){
            return this.$route.meta.noFooter;
        }
    },

    mounted() {
        this.initAnimations();
    },

    methods: {
        initAnimations() {
            if (!this.altMenu) {
                const animfooter = gsap.timeline({
                    scrollTrigger: {
                        trigger: 'footer',
                        start: '80% bottom',
                        end: 'bottom bottom',
                    },
                });

                animfooter.from('.footer-content__above__addresses', {
                    opacity: 0,
                    yPercent: 10,
                    duration: 1,
                    transformOrigin: 'left center',
                    ease: 'power3.inOut',
                });

                animfooter.from(
                    '.footer-content__line',
                    {
                        scaleX: 0,
                        duration: 1,
                        transformOrigin: 'left center',
                        ease: 'power3.inOut',
                    },
                    0.4
                );

                animfooter.from(
                    '.footer-content__below__links',
                    {
                        opacity: 0,
                        yPercent: 10,
                        duration: 1,
                        transformOrigin: 'left center',
                        ease: 'power3.inOut',
                    },
                    1.2
                );
            }
        },
    },
};
</script>
