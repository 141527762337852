<template>
    <main class="p-about" v-if="pageContent">
        <section class="simple-hero">
            <h1 v-html="pageContent.fields.hero_title"></h1>
            <div class="simple-hero__subtitle-cols">
                <div class="simple-hero__subtitle-cols__col" v-html="pageContent.fields.hero_subtitle_col1"></div>
                <div class="simple-hero__subtitle-cols__col" v-html="pageContent.fields.hero_subtitle_col2"></div>
            </div>
            <span class="rectangle rectangle--justundermiddle-section-left rectangle--offset-left"></span>
        </section>

        <section class="container section-intro-image">
            <div class="para-up">
                <img
                    v-if="pageContent.fields.intro_image"
                    :src="pageContent.fields.intro_image.url"
                    :alt="
                        pageContent.fields.alt ||
                            pageContent.fields.intro_image.url
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
                <div class="panes">
                    <div class="pane"></div>
                    <div class="pane"></div>
                </div>
            </div>
        </section>

        <section class="container bigwidth appear container--two-cols">
            <div class="image-container">
                <img
                    v-if="pageContent.fields.section1_image"
                    :src="pageContent.fields.section1_image.url"
                    :alt="
                        pageContent.fields.alt2 ||
                            pageContent.fields.section1_image.url
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
            </div>
            <div class="container--two-cols__text" v-html="pageContent.fields.section1_text"></div>
        </section>

        <section class="container keywords-section">
            <div class="appear-special">
                {{ this.$t('about.keywordsIntro') }}
                <div class="keywords-accordion">
                    <div class="keywords-accordion__item">
                        <a class="keyword" @click.prevent="toggleKeyword1">{{ this.$t('about.keyword1') }}</a>
                        <div class="keyword-content keyword1" :class="{ 'keyword-content--opened': keyword1Toggled }">
                            <div class="keyword-content__shape">
                                <svg class="svg-keyword-icon" height="17" viewBox="0 0 17 17" width="17" xmlns="http://www.w3.org/2000/svg">
                                    <circle :class="{ circlesvg: keyword1Toggled }" cx="8.5" cy="8.5" fill="none" r="8" stroke="#353d35" />
                                </svg>
                            </div>
                            <div class="keyword-content__text" v-html="pageContent.fields.keyword1_text"></div>
                        </div>
                    </div>
                    <div class="keywords-accordion__item">
                        <a class="keyword" @click.prevent="toggleKeyword2">{{ this.$t('about.keyword2') }}</a>
                        <div class="keyword-content keyword2" :class="{ 'keyword-content--opened': keyword2Toggled }">
                            <div class="keyword-content__shape">
                                <svg class="svg-keyword-icon" height="18" viewBox="0 0 19 18" width="19" xmlns="http://www.w3.org/2000/svg">
                                    <path :class="{ path: keyword2Toggled }" d="m934.5 2093 8.5 17h-17z" fill="none" stroke="#353d35" transform="translate(-925 -2093)" />
                                </svg>
                            </div>
                            <div class="keyword-content__text" v-html="pageContent.fields.keyword2_text"></div>
                        </div>
                    </div>
                    <div class="keywords-accordion__item">
                        <a class="keyword" @click.prevent="toggleKeyword3">{{ this.$t('about.keyword3') }}</a>
                        <div class="keyword-content keyword3" :class="{ 'keyword-content--opened': keyword3Toggled }">
                            <div class="keyword-content__shape">
                                <svg class="svg-keyword-icon" height="19" viewBox="0 0 19 19" width="19" xmlns="http://www.w3.org/2000/svg">
                                    <path :class="{ path: keyword3Toggled }" d="m1 1h17v17h-17z" fill="none" stroke="#353d35" />
                                </svg>
                            </div>
                            <div class="keyword-content__text" v-html="pageContent.fields.keyword3_text"></div>
                        </div>
                    </div>
                </div>
            </div>
            <span class="rectangle rectangle--justundermiddle-section-left rectangle--offset-left"></span>
        </section>

        <section class="fullwidth about-prefooter">
            <div class="about-prefooter__image">
                <div class="about-prefooter__image__external-circle"></div>
                <div class="about-prefooter__image__gradient-circle"></div>
                <img src="https://bissonfortin.s3.ca-central-1.amazonaws.com/uploads/prefooter-about.jpg" alt="architecte-qui-regarde-un-plan" />
            </div>

            <div class="about-prefooter__text">
                <span class="rectangle rectangle--prefooter"></span>
                <Cta :type="'title'" :sentences="titleCtaPrefooter" :ctaUrl="$t('nav.careers.slug')"></Cta>
                <div v-html="pageContent.fields.section_force_text"></div>
            </div>
        </section>
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import BaseView from './BaseView';
import Cta from '@/components/cta.vue';

export default {
    name: 'About',

    components: {
        Cta,
    },

    data() {
        return {
            keyword1Toggled: false,
            keyword2Toggled: false,
            keyword3Toggled: false,
        };
    },

    computed: {
        ...mapGetters(['pageContent', 'isLoadingRoute']),
        titleCtaPrefooter() {
            return {
                [this.$t('about.titleCtaPrefooter.textPart1')]: [this.$t('about.titleCtaPrefooter.textPart1Reveal')],
                [this.$t('about.titleCtaPrefooter.textPart2')]: 'cta',
            };
        },
    },

    watch: {
        isLoadingRoute(newValue, oldValue) {
            this.initAnimations();
        },
    },

    methods: {
        initAnimations() {
            gsap.set('.simple-hero h1', {
                y: 200,
                opacity: 0,
            });

            gsap.set('.simple-hero__subtitle-cols', {
                y: 300,
                opacity: 0,
            });

            gsap.set('.section-intro-image', {
                y: 200,
                opacity: 0,
            });

            gsap.to('.simple-hero h1', {
                y: 0,
                opacity: 1,
                duration: 0.7,
                ease: 'Power3.easeOut',
                delay: 0.5,
                onComplete: function() {
                    gsap.to('.simple-hero__subtitle-cols, .simple-hero h1', {
                        y: -150,
                        ease: 'Power1.easeOut',
                        scrollTrigger: {
                            trigger: '.simple-hero',
                            start: 'top top',
                            end: 'bottom top',
                            scrub: true,
                        },
                    });

                    gsap.to('.para-up', {
                        y: '-10vw',
                        ease: 'Power1.easeOut',
                        scrollTrigger: {
                            trigger: '.simple-hero',
                            start: 'top top',
                            end: 'bottom top',
                            scrub: true,
                        },
                    });
                },
            });

            gsap.to('.simple-hero__subtitle-cols', {
                y: 0,
                opacity: 1,
                duration: 0.7,
                delay: 0.5,
            });

            gsap.to('.section-intro-image', {
                y: 0,
                opacity: 1,
                duration: 0.6,
                delay: 0.7,
                ease: 'Power1.easeOut',
            });

            gsap.from('.appear-special', {
                y: '15vw',
                ease: 'Power1.easeOut',
                scrollTrigger: {
                    trigger: '.appear-special',
                    start: 'top bottom',
                    end: 'bottom bottom',
                    scrub: true,
                },
            });

            gsap.from('.keywords-accordion', {
                y: '15vw',
                opacity: 0,
                duration: 0.6,
                ease: 'Power3.easeOut',
                scrollTrigger: {
                    trigger: '.appear-special',
                    start: 'top bottom',
                    end: 'bottom bottom',
                },
            });

            gsap.from('.about-prefooter__image__external-circle', {
                scale: 0,
                duration: 0.8,
                delay: 0.7,
                ease: 'Power1.easeOut',
                scrollTrigger: {
                    trigger: '.about-prefooter',
                    start: 'top bottom',
                    end: 'bottom bottom',
                },
            });

            gsap.from('.about-prefooter__image__gradient-circle', {
                scale: 0,
                duration: 1,
                delay: 0.5,
                ease: 'Power3.easeOut',
                scrollTrigger: {
                    trigger: '.about-prefooter',
                    start: 'top bottom',
                    end: 'bottom bottom',
                },
            });

            gsap.from('.about-prefooter__image img', {
                scale: 0,
                duration: 1.2,
                delay: 0.5,
                ease: 'Power3.easeOut',
                scrollTrigger: {
                    trigger: '.about-prefooter',
                    start: 'top bottom',
                    end: 'bottom bottom',
                },
            });

            gsap.from('.about-prefooter__text', {
                y: 200,
                opacity: 0,
                duration: 0.6,
                delay: 0.7,
                ease: 'Power1.easeOut',
                scrollTrigger: {
                    trigger: '.about-prefooter__text',
                    start: 'top bottom',
                    end: 'bottom bottom',
                },
            });

            const sections = gsap.utils.toArray('.appear');
            sections.forEach((target) => {
                gsap.from(target, {
                    y: '10vw',
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: target,
                        start: 'top bottom',
                        end: 'bottom bottom',
                        scrub: true,
                    },
                });

                gsap.from(target, {
                    opacity: 0,
                    duration: 0.8,
                    ease: 'none',
                    scrollTrigger: {
                        trigger: target,
                        start: 'top bottom',
                        end: 'top bottom',
                    },
                });
            });

            gsap.to('.image-container img', {
                y: -100,
                ease: 'Power1.easeOut',
                scrollTrigger: {
                    trigger: '.image-container',
                    start: 'top bottom',
                    end: 'bottom bottom',
                    scrub: true,
                },
            });

            ScrollTrigger.refresh();
        },

        toggleKeyword1() {
            this.keyword1Toggled = !this.keyword1Toggled;
            gsap.to('.keyword1 .keyword-content__text', {
                y: 100,
                opacity: 0,
                duration: 0,
                ease: 'Power4.easeOut',
            });

            if (this.keyword1Toggled) {
                this.keyword2Toggled = false;
                this.keyword3Toggled = false;

                gsap.to('.keyword1 .keyword-content__text', {
                    y: 0,
                    opacity: 1,
                    duration: 1,
                    delay: 0.7,
                    ease: 'Power4.easeOut',
                });
            } else {
                gsap.to('.keyword1 .keyword-content__text', {
                    y: 100,
                    opacity: 0,
                    duration: 0,
                    ease: 'Power4.easeOut',
                });
            }

            setTimeout(() => {
                ScrollTrigger.refresh(true);
            }, 1000);
        },

        toggleKeyword2() {
            this.keyword2Toggled = !this.keyword2Toggled;

            gsap.to('.keyword2 .keyword-content__text', {
                y: 100,
                opacity: 0,
                duration: 0,
                ease: 'Power4.easeOut',
            });

            if (this.keyword2Toggled) {
                this.keyword1Toggled = false;
                this.keyword3Toggled = false;

                gsap.to('.keyword2 .keyword-content__text', {
                    y: 0,
                    opacity: 1,
                    delay: 0.7,
                    duration: 1,
                    ease: 'Power4.easeOut',
                });
            } else {
                gsap.to('.keyword2 .keyword-content__text', {
                    y: 100,
                    opacity: 0,
                    duration: 0,
                    ease: 'Power4.easeOut',
                });
            }

            setTimeout(() => {
                ScrollTrigger.refresh(true);
            }, 1000);
        },

        toggleKeyword3() {
            this.keyword3Toggled = !this.keyword3Toggled;

            gsap.to('.keyword3 .keyword-content__text', {
                y: 100,
                opacity: 0,
                duration: 0,
                ease: 'Power4.easeOut',
            });

            if (this.keyword3Toggled) {
                this.keyword2Toggled = false;
                this.keyword1Toggled = false;

                gsap.to('.keyword3 .keyword-content__text', {
                    y: 0,
                    opacity: 1,
                    delay: 0.7,
                    duration: 1,
                    ease: 'Power4.easeOut',
                });
            } else {
                gsap.to('.keyword3 .keyword-content__text', {
                    y: 100,
                    opacity: 0,
                    duration: 0,
                    ease: 'Power4.easeOut',
                });
            }

            setTimeout(() => {
                ScrollTrigger.refresh(true);
            }, 1000);
        },
    },

    extends: BaseView,
};
</script>
