<template>
    <main class="p-careers" v-if="pageContent">
        <section class="simple-hero">
            <h1 v-html="this.$t('careers.title')"></h1>
            <div class="simple-hero__subtitle" v-html="pageContent.fields.hero_subtitle"></div>
            <span class="rectangle rectangle--justundermiddle-section-right rectangle--offset-right rectangle--hidden-tablet"></span>
        </section>

        <section class="container fullwidth container--two-cols section-1">
            <div class="img-left-panes">
                <div class="image-container">
                    <img
                        v-if="pageContent.fields.section1_image"
                        :src="pageContent.fields.section1_image.url"
                        :alt="
                            pageContent.fields.alt ||
                                pageContent.fields.section1_image.url
                                    .split('/')
                                    .pop()
                                    .split('.')[0]
                        "
                    />
                </div>
                <div class="panes">
                    <div class="pane"></div>
                    <div class="pane"></div>
                </div>
            </div>
            <div class="container--two-cols__text" v-html="pageContent.fields.section1_text"></div>
        </section>

        <section class="container bigwidth appear container--two-cols section-2">
            <div class="container--two-cols__text textanim" v-html="pageContent.fields.section2_text"></div>
            <div>
                <img
                    v-if="pageContent.fields.section2_image"
                    :src="pageContent.fields.section2_image.url"
                    :alt="
                        pageContent.fields.alt2 ||
                            pageContent.fields.section2_image.url
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
            </div>
        </section>

        <div class="bg-dark overflow-top">
            <span class="rectangle rectangle--offset-left"></span>
            <section class="container jobs-section">
                <h4 class="jobs-section__title textanim" id="jobs">{{ $t('careers.titleIntroJobs') }}</h4>

                <span class="rectangle rectangle--justundermiddle-section-left"></span>
                <Job v-for="(job, index) in jobs" :key="index" :job="job"></Job>
                <div class="jobs-section__pagination">
                    <a class="page-link" :class="{ 'page-link--active': page == currentPage }" v-for="page in totalPages" :key="page" @click="changePage(page)">{{ page }}.</a>
                </div>
            </section>

            <section class="appear">
                <div class="container offset-tablet extra-mb title-slider textanim">
                    <span class="rectangle rectangle-slider-titre rectangle--title-left"></span>
                    <h2>{{ $t('careers.titleTeamSlider') }}</h2>
                </div>
                <div class="container bigwidth norpadding">
                    <Slider v-if="pageContent.fields.team_block_slider" :id="'team-slider'" :quotes="true" :slides="pageContent.fields.team_block_slider"></Slider>
                    <svg class="monogram-slider" height="219" viewBox="0 0 552 219" width="552" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" stroke="#5a605a" transform="translate(1 1)">
                            <path
                                class="b-cta-monogram"
                                d="m60.1321586 29.2560404c5.0110132 3.0057576 9.0198238 7.414202 12.0264317 13.2253333 2.8061674 5.8111314 4.4096916 12.6241819 4.4096916 20.2387677 0 7.8149697-1.4030837 14.6280202-4.4096916 20.4391515-2.8061674 5.8111313-6.814978 10.2195758-12.0264317 13.2253334-5.0110132 3.0057575-10.8237886 4.6088287-17.438326 4.6088287-6.814978 0-12.6277533-1.6030712-17.2378855-5.0095964-4.8105727-3.4065253-8.0176211-7.8149697-10.0220264-13.2253333v17.2330101h-15.4339207v-99.9915354h15.4339207v42.8821414c1.8039648-5.4103636 5.2114537-9.8188081 10.0220264-13.2253333 4.8105727-3.4065253 10.6233481-5.009596 17.2378855-5.009596s12.2268722 1.4026869 17.438326 4.6088283zm-33.6740088 11.8226465c-3.407489 2.0038384-6.2136564 5.0095959-8.0176212 8.616505-2.0044053 3.8072929-3.0066079 8.0153536-3.0066079 12.8245657 0 5.0095959 1.0022026 9.2176565 3.0066079 13.0249495 2.0044053 3.6069091 4.6101322 6.6126666 8.0176212 8.616505s7.215859 3.0057576 11.6255506 3.0057576c6.814978 0 12.4273128-2.2042222 16.4361234-6.6126667 4.2092511-4.4084444 6.2136564-10.4199596 6.2136564-18.0345454 0-7.6145859-2.0044053-13.626101-6.2136564-18.0345455-4.2092511-4.4084444-9.6211454-6.4122828-16.4361234-6.4122828-4.4096916 0-8.2180616 1.0019192-11.6255506 3.0057576z"
                            />
                            <path
                                class="f-cta-monogram"
                                d="m226.898678 119.428768c-5.411894 4.608828-8.017621 11.421878-8.017621 20.439151v2.404606h-10.222467v13.225334h10.222467v61.317454h15.634362v-61.117071h17.237885v-13.425717h-17.237885v-1.202303c0-5.410363 1.202643-9.017273 3.607929-11.221495s7.4163-3.206141 13.429515-3.005757v-13.425717c-10.623348-.601152-19.442731 1.402686-24.654185 6.011515z"
                            />
                            <path class="line-cta-monogram" d="m429.544053 86.966586h120.063877v13.02495h-120.063877z" />
                        </g>
                    </svg>
                </div>
            </section>
        </div>
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import BaseView from './BaseView';
import EventBus from '../eventbus';
import axios from 'axios';
import Job from '@/components/job.vue';
import Slider from '@/components/slider.vue';
import ContactVue from './Contact.vue';

export default {
    name: 'Careers',

    components: {
        Job,
        Slider,
    },

    data() {
        return {
            jobs: [],
            totalPages: 1,
            currentPage: 1,
        };
    },

    computed: {
        ...mapGetters(['pageContent', 'isLoadingRoute']),
    },

    watch: {
        isLoadingRoute(newValue, oldValue) {
            this.initAnimations();
        },
    },

    created() {
        this.$store.commit('SET_OPENED_JOB_ID', '');

        axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL}/api/get-jobs?locale=${this.$i18n.locale}`)
            .then((res) => {
                const result = JSON.parse(res.data.message);
                this.jobs = result.jobs;
                this.totalPages = result.pagination.total_pages;
            })
            .then(() => {
                setTimeout(() => {
                    ScrollTrigger.refresh(true);
                }, 1000);
            })
            .catch((err) => {});
    },

    updated() {
        ScrollTrigger.refresh(true);
    },

    methods: {
        changePage(page) {
            const jobsTitle = document.querySelector('#jobs');
            const topPos = jobsTitle.getBoundingClientRect().top + window.pageYOffset - 120;

            EventBus.$emit('ASSCROLL_TO', topPos);

            axios
                .get(`${process.env.VUE_APP_SITE_BASE_URL}/api/get-jobs?locale=${this.$i18n.locale}&page=${page}`)
                .then((res) => {
                    const result = JSON.parse(res.data.message);
                    this.jobs = result.jobs;
                    this.currentPage = page;
                })
                .then(() => {
                    setTimeout(() => {
                        ScrollTrigger.refresh(true);
                    }, 1000);
                })
                .catch((err) => {});
        },

        initAnimations() {
            gsap.set('.simple-hero', {
                y: 300,
                opacity: 0,
            });

            gsap.set('.section-1', {
                y: 200,
                opacity: 0,
            });

            const animationMono = gsap.timeline({});
            animationMono.from('.b-cta-monogram', { x: 200, opacity: 0, duration: 0.6, ease: 'Power4.easeOut' }, '+=0.4');
            animationMono.from('.f-cta-monogram', { x: 150, opacity: 0, duration: 0.6, ease: 'Power4.easeOut' }, '-=0.3');
            animationMono.from('.line-cta-monogram', { x: 150, opacity: 0, duration: 0.6, ease: 'Power4.easeOut' }, '-=0.4');

            ScrollTrigger.create({
                trigger: '.monogram-slider',
                animation: animationMono,
                start: 'center bottom',
                end: 'bottom bottom',
                onEnter: () => {
                    document.querySelector('.b-cta-monogram').classList.add('animation-path-mono-b');
                    document.querySelector('.f-cta-monogram').classList.add('animation-path-mono-f');
                    document.querySelector('.line-cta-monogram').classList.add('animation-path-mono-line');
                },
            });

            gsap.to('.simple-hero', {
                y: 0,
                opacity: 1,
                duration: 0.7,
                ease: 'Power1.easeOut',
                delay: 0.7,
                onComplete: function() {
                    gsap.to('.simple-hero__subtitle, .simple-hero h1', {
                        y: -50,
                        ease: 'Power3.easeOut',
                        scrollTrigger: {
                            trigger: '.simple-hero',
                            start: 'top top',
                            end: 'bottom top',
                            scrub: true,
                        },
                    });

                    gsap.to('.section-1 .img-left-panes , .section-1 .container--two-cols__text', {
                        y: -150,
                        ease: 'Power1.easeOut',
                        scrollTrigger: {
                            trigger: '.section-1',
                            start: 'top top',
                            end: 'bottom top',
                            scrub: true,
                        },
                    });
                },
            });

            gsap.to('.section-1', {
                y: 0,
                opacity: 1,
                duration: 1,
                delay: 1,
                ease: 'Power3.easeOut',
            });

            const sections = gsap.utils.toArray('.appear');
            sections.forEach((section) => {
                gsap.set(section, { opacity: 0 });
                gsap.from(section, {
                    y: '10vw',
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: section,
                        start: 'top bottom',
                        end: 'bottom bottom',
                        scrub: true,
                    },
                });

                ScrollTrigger.create({
                    trigger: section,
                    start: 'top bottom',
                    end: 'bottom bottom',
                    onEnter: function() {
                        gsap.to(section, {
                            opacity: 1,
                            duration: 0.6,
                        });
                    },
                });
            });

            gsap.to('.image-container img', {
                y: -100,
                ease: 'Power1.easeOut',
                scrollTrigger: {
                    trigger: '.image-container',
                    start: 'top center',
                    end: 'bottom top',
                    scrub: true,
                },
            });

            const texts = gsap.utils.toArray('.textanim');
            texts.forEach((text) => {
                gsap.set(text, { y: 100, opacity: 0 });

                ScrollTrigger.create({
                    trigger: text,
                    start: 'top bottom',
                    end: 'bottom bottom',
                    onEnter: function() {
                        gsap.to(text, {
                            y: 0,
                            opacity: 1,
                            duration: 0.6,
                            ease: 'Power1.easeOut',
                        });
                    },
                });
            });

            const jobs = gsap.utils.toArray('.job');
            jobs.forEach((job) => {
                gsap.set(job, { y: 100, opacity: 0 });

                ScrollTrigger.create({
                    trigger: job,
                    start: 'top bottom',
                    end: 'bottom bottom',
                    onEnter: function() {
                        gsap.to(job, {
                            y: 0,
                            opacity: 1,
                            duration: 0.6,
                            ease: 'Power1.easeOut',
                        });
                    },
                });
            });

            ScrollTrigger.refresh();

            const langColorchange = gsap.timeline({ paused: true });
            langColorchange.to('.language-switcher', { backgroundColor: '#ffffff' }, 0);
            langColorchange.to('.language-switcher .inner', { color: '#353D35' }, 0);

            ScrollTrigger.create({
                trigger: '.bg-dark',
                toggleClass: 'bg-dark-active',
                start: 'top top',
                end: 'bottom top',
                onEnter: () => langColorchange.play(0),
            });
        },
    },

    extends: BaseView,
};
</script>
