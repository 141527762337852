import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';
import i18n from '../i18n';
import axios from 'axios';
import headful from 'headful';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        isInitialLoad: true,
        isDataReady: false,
        isLoadingRoute: false,
        isLoadingProjectRoute: false,
        isChangingProject: false,
        pageContent: '',
        nextProjectImageUrl: '',
        openedJobId: '',
    },
    mutations: {
        SET_INITIAL_LOAD(state, status) {
            state.isInitialLoad = status;
        },
        SET_DATA_READY(state, status) {
            state.isDataReady = status;
        },
        SET_LOADING_ROUTE(state, status) {
            state.isLoadingRoute = status;
        },
        SET_LOADING_ROUTE_PROJECT(state, status) {
            state.isLoadingProjectRoute = status;
        },
        SET_PROJECT_TRANSITION(state, status) {
            state.isChangingProject = status;
        },
        SET_PAGE_CONTENT(state, page) {
            state.pageContent = page;
        },
        SET_NEXT_PROJECT_IMAGE_URL(state, url) {
            state.nextProjectImageUrl = url;
        },
        SET_OPENED_JOB_ID(state, id) {
            state.openedJobId = id;
        },
    },
    actions: {
        toggleInitialLoading({ commit }, status) {
            if (status) {
                commit('SET_INITIAL_LOAD', true);
            } else {
                commit('SET_INITIAL_LOAD', false);
            }
        },
        toggleDataReady({ commit }, status) {
            if (status) {
                commit('SET_DATA_READY', true);
            } else {
                commit('SET_DATA_READY', false);
            }
        },
        toggleLoadingRoute({ commit }, status) {
            if (status) {
                commit('SET_LOADING_ROUTE', true);
            } else {
                commit('SET_LOADING_ROUTE', false);
            }
        },
        getPageContents({ commit }, path) {
            let locales = Object.keys(i18n.messages),
                slug;

            path = path.replace(/^\/+|\/+$/g, '');

            if (path !== '') {
                path = path.split('/');
                i18n.locale = locales.filter((locale) => locale === [...path].shift()).length ? path.shift() : i18n.locale || 'en';
            } else {
                path = [];
            }

            slug = path.length ? path.pop() : i18n.t('settings.slug.home') || 'home';
            if (slug === '404') {
                commit('SET_DATA_READY', true);
                commit('SET_LOADING_ROUTE_PROJECT', false);
                setTimeout(() => {
                    commit('SET_LOADING_ROUTE', false);
                }, 2000);
                return;
            }
            slug = `${slug}?locale=${i18n.locale || 'fr'}`;

            // Get page content from Sail
            axios
                .get(`${process.env.VUE_APP_SITE_BASE_URL}/api/content/pages/${slug}`)
                .then((res) => {
                    if (res.code && res.code !== 200) {
                        const loc = i18n.locale === 'en' ? '/en' : '';
                        router.push(`${loc}/404`);
                        return;
                    }

                    const content = res.data.page;
                    commit('SET_PAGE_CONTENT', content);

                    // Set metas
                    let [slugStr] = slug.split('?'),
                        title =
                            content.seo.title || (slugStr === 'home' || slugStr === 'fr' ? `Bisson Fortin | ${i18n.t('settings.siteTitle')}` : `${content.title} | ${i18n.t('settings.siteTitle')}`);

                    headful({
                        lang: i18n.locale,
                        title: title,
                        description: content.seo.description || i18n.t('settings.siteDesc'),
                        image: i18n.t('settings.ogImage'),
                        url: content.fields.canonical || window.location.href,
                    });

                    commit('SET_DATA_READY', true);
                    setTimeout(() => {
                        // Allow some time for page transitions
                        commit('SET_LOADING_ROUTE_PROJECT', false);
                    }, 600);
                    setTimeout(() => {
                        // Allow some time for page transitions
                        commit('SET_LOADING_ROUTE', false);
                    }, 1000);
                })
                .catch((err) => {
                    if (err.response.status === 404) {
                        const loc = i18n.locale === 'en' ? '/en' : '';
                        router.push(`${loc}/404`);
                        return;
                    }

                    // eslint-disable-next-line no-console
                    console.error(err);
                });
        },
    },
    getters: {
        isInitialLoad: (state) => state.isInitialLoad,
        isDataReady: (state) => state.isDataReady,
        isLoadingRoute: (state) => state.isLoadingRoute,
        isLoadingProjectRoute: (state) => state.isLoadingProjectRoute,
        isChangingProject: (state) => state.isChangingProject,
        pageContent: (state) => state.pageContent,
        nextProjectImageUrl: (state) => state.nextProjectImageUrl,
        openedJobId: (state) => state.openedJobId,
    },
});
