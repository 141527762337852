var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.pageContent)?_c('main',{staticClass:"p-project"},[_c('section',{staticClass:"hero cursor-cta cursor-scroll"},[(_vm.pageContent.fields.img_hero)?_c('img',{attrs:{"src":_vm.pageContent.fields.img_hero.url,"alt":_vm.pageContent.fields.alt ||
                    _vm.pageContent.fields.img_hero.url
                        .split('/')
                        .pop()
                        .split('.')[0]}}):_vm._e()]),_c('section',{staticClass:"container intro"},[_c('div',{staticClass:"textanim"},[_c('span',{staticClass:"intro__plus"},[_c('svg',{attrs:{"height":"10","viewBox":"0 0 10 10","width":"10","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"fill":"none","fill-rule":"evenodd","stroke":"#101010"}},[_c('path',{attrs:{"d":"m5 0v10"}}),_c('path',{attrs:{"d":"m10 5h-10"}})])])]),_c('div',{staticClass:"intro__category"},[_vm._v(_vm._s(_vm.pageContent.fields.category))]),_c('h1',{staticClass:"intro__name"},[_vm._v(_vm._s(_vm.pageContent.title))]),_c('span',{staticClass:"intro__line"}),_c('div',{staticClass:"intro__infos"},[_c('div',[_c('span',{staticClass:"intro__infos__label"},[_vm._v(_vm._s(_vm.$t('project.customer')))]),_vm._v(_vm._s(_vm.pageContent.fields.customer)+" ")]),_c('div',[_c('span',{staticClass:"intro__infos__label"},[_vm._v(_vm._s(_vm.$t('project.date')))]),_vm._v(_vm._s(_vm.pageContent.fields.date)+" ")]),(_vm.pageContent.fields.certifications)?_c('div',{staticClass:"intro__infos__certifications"},[_c('span',{staticClass:"intro__infos__label"},[_vm._v(_vm._s(_vm.$t('project.certifications')))]),_vm._l((_vm.pageContent.fields.certifications.slice(0, 10)),function(certification,index){return _c('p',{key:index,staticClass:"intro__infos__certification",class:{ 'certification-hide': index > 4 && !_vm.seeMoreCertifications }},[_vm._v(" "+_vm._s(certification.title)+" ")])}),(_vm.pageContent.fields.certifications.length > 4)?_c('Cta',{staticClass:"is-certifications",attrs:{"type":'more'},nativeOn:{"click":function($event){$event.preventDefault();return _vm.seeMore()}}}):_vm._e()],2):_vm._e()])])]),_vm._l((_vm.pageContent.fields.blocks),function(block,index){return _c('section',{key:index,staticClass:"container block",class:{ fullwidth: block.matrix_element_type === 'image_fullwidth' }},[(block.matrix_element_type === 'image' && block.image)?_c('img',{attrs:{"src":block.image.url,"alt":block.alt ||
                    block.image.url
                        .split('/')
                        .pop()
                        .split('.')[0]}}):(block.matrix_element_type === 'image_fullwidth' && block.image)?_c('div',{staticClass:"image-container"},[_c('img',{attrs:{"src":block.image.url,"alt":block.alt ||
                        block.image.url
                            .split('/')
                            .pop()
                            .split('.')[0]}})]):(block.matrix_element_type === 'text')?_c('div',{staticClass:"text"},[_c('span',{staticClass:"rectangle rectangle--top-section-left"}),_c('div',{staticClass:"text__content textanim",domProps:{"innerHTML":_vm._s(block.text)}})]):(block.matrix_element_type === 'centeredtext')?_c('div',{staticClass:"text centered textanim",domProps:{"innerHTML":_vm._s(block.text)}}):(block.image_left && block.image_right)?_c('div',{staticClass:"two-cols"},[_c('img',{attrs:{"src":block.image_left.url,"alt":block.alt ||
                        block.image_left.url
                            .split('/')
                            .pop()
                            .split('.')[0]}}),_c('img',{attrs:{"src":block.image_right.url,"alt":block.alt2 ||
                        block.image_right.url
                            .split('/')
                            .pop()
                            .split('.')[0]}})]):_vm._e()])}),(_vm.related.length > 3)?_c('section',{staticClass:"simple-hero related-hero"},[_c('h2',{staticClass:"title-cta"},[_vm._v(" "+_vm._s(_vm.$t('project.relatedProjectsTitle'))+" ")]),(_vm.pageContent.categories[0])?_c('p',{staticClass:"simple-hero__subtitle"},[_vm._v(_vm._s(_vm.$t(("project.relatedProjectsText." + (_vm.pageContent.categories[0].slug.en)))))]):_c('p',{staticClass:"simple-hero__subtitle"},[_vm._v(_vm._s(_vm.$t("project.relatedProjectsText.default")))])]):_vm._e(),(_vm.related.length > 3)?_c('section',{staticClass:"projects container bigwidth appear"},[_c('div',{staticClass:"masonry-wrapper"},[_c('Masonry',{attrs:{"projects":_vm.related.slice(0, 4)}})],1)]):_vm._e(),_c('section',{staticClass:"wrapper-nextproject cursor-cta cursor-scroll"},[_c('div',{staticClass:"wrapper-nextproject__content"},[_c('h2',{staticClass:"wrapper-nextproject__title",on:{"click":function($event){return _vm.nextpage()}}},[_vm._v(_vm._s(_vm.$t('project.nextProject')))]),_c('div',{staticClass:"wrapper-nextproject__scrollanim"})]),_c('div',{staticClass:"progress"})])],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }