<template>
    <h2 class="title-cta" :id="id" v-if="type == 'title'" @mouseenter="forceDisplayCTA" :class="{ 'title-cta--in-viewport': titleCtaForceDisplay }">
        <span v-for="(newText, originalText) in sentences" :key="originalText">
            <span v-if="newText == 'cta'" class="outer">
                <a :href="ctaUrl" class="cta">{{ originalText }}</a>
            </span>
            <span v-else :data-text="newText" :data-originaltext="originalText" v-html="originalText" class="text" :class="{ 'd-block': breakSentences }"></span>
        </span>
    </h2>

    <a v-else-if="type == 'outline'" class="outline-cta" :href="ctaUrl"
        >{{ ctaText
        }}<span class="outline-cta__arrow">
            <svg height="11" viewBox="0 0 12 11" width="12" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd" stroke="#353d35" transform="translate(1 1)">
                    <path d="m0 0h10v10" />
                    <path d="m10 0-10 10" />
                </g>
            </svg>
        </span>
    </a>

    <div v-else-if="type == 'big'" class="big-cta">
        <a :href="ctaUrl">{{ ctaText }}</a>
    </div>

    <a v-else-if="type == 'more'" class="more-cta" :class="{ 'more-cta--opened': seeMoreToggled }" :href="ctaUrl" @click="toggleMore()">
        <span class="more-cta__plus">
            <svg height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
                <g fill="none" fill-rule="evenodd" stroke="#5A605A">
                    <path d="m5 0v10" />
                    <path d="m10 5h-10" />
                </g>
            </svg>
        </span>
        {{ seeMoreToggled ? $t('global.ctaSeeLess') : $t('global.ctaSeeMore') }}
    </a>

    <a v-else class="secondary-cta" :href="ctaUrl">{{ ctaText }}</a>
</template>

<script>
export default {
    name: 'Cta',

    props: {
        type: { default: 'title', type: String },
        id: { type: String },
        rectangle: { type: String },
        ctaUrl: { default: '#', type: String },
        ctaText: { default: '', type: String },
        sentences: { type: Object },
        breakSentences: { type: Boolean },
        forceStatus: { default: null, type: Boolean },
    },

    data() {
        return {
            seeMoreToggle: false,
            titleCtaForceDisplay: false,
        };
    },

    computed: {
        seeMoreToggled() {
            if (this.forceStatus !== null) {
                return this.forceStatus;
            } else return this.seeMoreToggle;
        },
    },

    methods: {
        toggleMore() {
            event.preventDefault();
            this.seeMoreToggle = !this.seeMoreToggle;
        },

        forceDisplayCTA() {
            this.titleCtaForceDisplay = true;
        },
    },
};
</script>
