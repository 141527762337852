<template>
    <main class="p-contact" v-if="pageContent">
        <section class="intro">
            <img
                v-if="pageContent.fields.intro_image"
                :src="pageContent.fields.intro_image.url"
                :alt="
                    pageContent.fields.alt ||
                        pageContent.fields.intro_image.url
                            .split('/')
                            .pop()
                            .split('.')[0]
                "
            />
            <div class="intro__text">
                <h1>{{ pageContent.fields.intro_title }}</h1>
                <div class="intro__text__subtitle" v-html="pageContent.fields.intro_subtitle"></div>
            </div>
        </section>

        <div class="bg-dark overflow-top">
            <section class="container">
                <span class="rectangle rectangle--middle-section-left rectangle--offset-left"></span>
                <span class="rectangle rectangle--bottom-section-right rectangle--hidden-tablet"></span>
                <div class="office" v-for="(office, index) in pageContent.fields.offices" :key="index">
                    <div class="office__infos">
                        <div class="title">{{ office.title }}</div>
                        <div class="address" v-html="office.address"></div>
                        <div class="office__infos__line"></div>
                        <div class="contact">
                            <div v-if="office.phone">
                                <span class="contact__label">{{ $t('contact.phone') }}</span> {{ office.phone }}
                            </div>
                            <div v-if="office.email">
                                <span class="contact__label">{{ $t('contact.mail') }}</span> <a :href="'mailto:' + office.email">{{ office.email }}</a>
                            </div>
                        </div>
                        <div class="maps" v-if="office.maps">
                            <svg xmlns="http://www.w3.org/2000/svg" width="9" height="12" viewBox="0 0 9 12">
                                <g fill="none" fill-rule="evenodd" stroke="#FFF">
                                    <path
                                        d="M4.5.5C3.395.5 2.395.948 1.672 1.672.948 2.395.5 3.395.5 4.5c0 1.52 1.377 3.718 4 6.616 2.623-2.898 4-5.095 4-6.616 0-1.105-.448-2.105-1.172-2.828C6.605.948 5.605.5 4.5.5z"
                                    />
                                    <circle cx="4.5" cy="4.5" r="1.1" stroke-width=".8" />
                                </g>
                            </svg>
                            <a :href="office.maps" target="_blank" rel="noopener noreferrer">{{ $t('contact.maps') }}</a>
                        </div>
                    </div>
                    <div class="image-container">
                        <img
                            v-if="office.image"
                            class="office__image"
                            :src="office.image.url"
                            :alt="
                                office.alt ||
                                    office.image.url
                                        .split('/')
                                        .pop()
                                        .split('.')[0]
                            "
                        />
                    </div>
                </div>
            </section>

            <section class="contact-cta">
                <Cta :type="'big'" :ctaUrl="$t('nav.careers.slug')" :ctaText="$t('contact.cta')"></Cta>
            </section>
        </div>
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import BaseView from './BaseView';
import Cta from '@/components/cta.vue';

export default {
    name: 'Contact',

    components: {
        Cta,
    },

    computed: {
        ...mapGetters(['pageContent', 'isLoadingRoute']),
    },

    watch: {
        isLoadingRoute(newValue, oldValue) {
            this.initAnimations();
        },
    },

    methods: {
        initAnimations() {
            gsap.set('.intro', {
                y: 200,
                opacity: 0,
            });

            gsap.to('.intro', {
                y: 0,
                opacity: 1,
                duration: 0.6,
                ease: 'Power3.easeOut',
                delay: 0.3,
                onComplete: function() {
                    gsap.to('.intro', {
                        delay: 0,
                        y: -150,
                        ease: 'Power1.easeOut',
                        scrollTrigger: {
                            trigger: '.intro',
                            start: 'top top',
                            end: 'bottom top',
                            scrub: true,
                        },
                    });
                },
            });

            gsap.set('.container', {
                y: '15vw',
                opacity: 0,
            });

            gsap.to('.container', {
                y: 0,
                opacity: 1,
                duration: 0.6,
                ease: 'Power3.easeOut',
                onComplete: function() {
                    animationdanscontainer();
                },
            });

            const offices = gsap.utils.toArray('.office');
            offices.forEach((office) => {
                gsap.set(office, {
                    opacity: 0,
                });
            });
            function animationdanscontainer() {
                offices.forEach((office) => {
                    gsap.from(office, {
                        y: 200,
                        ease: 'Power1.easeOut',
                        scrollTrigger: {
                            trigger: office,
                            start: 'top bottom',
                            end: 'bottom top',
                            scrub: true,
                        },
                    });

                    ScrollTrigger.create({
                        trigger: office,
                        start: 'top bottom',
                        end: 'bottom top',
                        onEnter: function() {
                            gsap.to(office, {
                                opacity: 1,
                                duration: 0.7,
                                ease: 'Power1.easeOut',
                            });
                        },
                    });
                });
            }

            gsap.from('.contact-cta', {
                y: 200,
                ease: 'Power1.easeOut',
                scrollTrigger: {
                    trigger: '.contact-cta',
                    start: 'top bottom',
                    end: 'bottom bottom',
                    scrub: true,
                },
            });

            ScrollTrigger.create({
                trigger: '.contact-cta',
                start: 'top bottom',
                end: 'bottom bottom',
                onEnter: function() {
                    gsap.from('.big-cta', {
                        opacity: 0,
                        duration: 1,
                    });
                },
            });

            const images = gsap.utils.toArray('.image-container');
            images.forEach((image) => {
                gsap.to(image.children[0], {
                    y: -100,
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: image,
                        start: 'top bottom',
                        end: 'bottom bottom',
                        scrub: true,
                    },
                });
            });

            ScrollTrigger.refresh();
        },
    },

    extends: BaseView,
};
</script>
