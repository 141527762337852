import axios from 'axios';
import fr from '../i18n/fr.json';

// Extract image URLs from pageContent
function extractImageUrls(htmlContent) {
    let imageUrls = [];
    const urlRegex = /https:\\\/\\\/bissonfortin\.s3\.ca-central-1\.amazonaws\.com\\\/uploads\\\/[^"]+\.(jpg|jpeg|png|gif|bmp)/gi;
    const content = `${htmlContent.documentElement.innerHTML}`;
    imageUrls = content.match(urlRegex);
    if (!imageUrls) {
        return [];
    }
    const uniqueUrls = [...new Set(imageUrls.map((url) => url.replace(/\\\//g, '/')))];
    return uniqueUrls;
}

// Preload images
function preloadImages(imageUrls) {
    imageUrls.forEach((url) => {
        const img = new Image();
        img.src = url;
    });
}

export default function preload() {
    const slugs = [fr.nav.about.slug, fr.nav.careers.slug, fr.nav.contact.slug, fr.nav.home.slug, fr.nav.projects.slug, fr.nav.identity.slug];
    slugs.forEach((slug) => {
        axios.get(`${process.env.VUE_APP_SITE_BASE_URL}/api/content/pages${slug}`, { responseType: 'document' }).then((res) => {
            if (res.code && res.code !== 200) {
                const loc = i18n.locale === 'en' ? '/en' : '';
                router.push(`${loc}/404`);
                return;
            }

            const content = res.data;
            const imageUrls = extractImageUrls(content);
            preloadImages(imageUrls);
        });
    });
}
