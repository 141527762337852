<template>
    <div class="cursor cursor--small">
        <span class="cursor__dots">
            <span class="cursor__dots__dot"></span>
            <span class="cursor__dots__dot"></span>
        </span>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    data() {
        return {
            clientX: -100,
            clientY: -100,
            innerCursor: null,
            links: [],
            ctaElements: [],
            darkSections: [],
            jobSections: [],
            languageSwitcher: [],
        };
    },

    computed: {
        ...mapGetters([
            'isLoadingRoute',
        ]),
        altMenu() {
            return this.$route.meta.altMenu;
        },
    },

    watch: {
        isLoadingRoute(newValue, oldValue) {
            if (newValue) {
                this.innerCursor.className = 'cursor cursor--small'; // Reset classes
                this.removeEventListeners();
            }
            else this.initCursorAnimations();
        },
    },

    mounted() {
        this.innerCursor = document.querySelector('.cursor--small');

        // Add listener to track the current mouse position
        window.addEventListener('mousemove', this.handleMouseMove);

        // Transform the innerCursor to the current mouse position
        const render = () => {
            this.innerCursor.style.transform = `translate(${this.clientX}px, ${this.clientY}px)`;
            requestAnimationFrame(render);
        };
        requestAnimationFrame(render);
    },

    methods: {
        initCursorAnimations() {
            // Cursors animations on elements
            // Links
            this.links = document.querySelectorAll('a');
            this.links.forEach((el) => {
                el.addEventListener('mouseenter', this.handleLinkHoverEnter);
                el.addEventListener('mouseleave', this.handleLinkHoverLeave);
                el.addEventListener('click', this.handleLinkClick);
            });

            // Language switcher
            if (!this.altMenu) {
                this.languageSwitcher = document.querySelector('.language-switcher');
                this.languageSwitcher.addEventListener('mouseenter', this.handleLanguageSwitcherHoverEnter);
                this.languageSwitcher.addEventListener('mouseleave', this.handleLanguageSwitcherHoverLeave);
            }

            // Dark background sections
            this.darkSections = document.querySelectorAll('.bg-dark');
            this.darkSections.forEach((el) => {
                el.addEventListener('mouseenter', this.handleDarkSectionHoverEnter);
                el.addEventListener('mouseleave', this.handleDarkSectionHoverLeave);
            });

            // Job sections (white background)
            this.jobSections = document.querySelectorAll('.job');
            this.jobSections.forEach((el) => {
                el.addEventListener('mouseenter', this.handleDarkSectionHoverLeave);
                el.addEventListener('mouseleave', this.handleDarkSectionHoverEnter);
            });

            // CTA elements
            this.ctaElements = document.querySelectorAll('.cursor-cta');
            this.ctaElements.forEach((el) => {
                if (el.classList.contains('cursor-scroll')) {
                    el.ctaAction = 'scroll';
                }
                if (el.classList.contains('cursor-drag')) {
                    el.ctaAction = 'drag';
                }
                if (el.classList.contains('cursor-discover')) {
                    el.ctaAction = 'discover';
                }
                el.addEventListener('mouseenter', this.handleCtaHoverEnter);
                el.addEventListener('mouseleave', this.handleCtaHoverLeave);
            });
        },

        // We need methods to remove later in removeEventListener

        handleMouseMove(evt) {
            this.clientX = evt.clientX;
            this.clientY = evt.clientY;
        },

        handleLinkHoverEnter() {
            this.innerCursor.classList.add('cursor--small--hovering-simple');
        },
        handleLinkHoverLeave() {
            this.innerCursor.classList.remove('cursor--small--hovering-simple');
        },
        handleLinkClick() {
            this.innerCursor.classList.add('cursor--small--click-simple');
            setTimeout(() => {
                this.innerCursor.classList.remove('cursor--small--click-simple');
            }, 500);
        },

        handleLanguageSwitcherHoverEnter() {
            this.innerCursor.classList.add('cursor--small--hovering-ls');
        },
        handleLanguageSwitcherHoverLeave() {
            this.innerCursor.classList.remove('cursor--small--hovering-ls');
        },

        handleDarkSectionHoverEnter() {
            this.innerCursor.classList.add('cursor--small--hovering-darkbg');
        },
        handleDarkSectionHoverLeave() {
            this.innerCursor.classList.remove('cursor--small--hovering-darkbg');
        },

        handleCtaHoverEnter(evt) {
            this.innerCursor.classList.add(`cursor--small--cta-${evt.currentTarget.ctaAction}`);
        },
        handleCtaHoverLeave(evt) {
            this.innerCursor.classList.remove(`cursor--small--cta-${evt.currentTarget.ctaAction}`);
        },

        removeEventListeners() {
            this.links.forEach((el) => {
                el.removeEventListener('mouseenter', this.handleLinkHoverEnter);
                el.removeEventListener('mouseleave', this.handleLinkHoverLeave);
                el.removeEventListener('click', this.handleLinkClick);
            });

            if (!this.altMenu && this.languageSwitcher && this.languageSwitcher.removeEventListener) {
                this.languageSwitcher.removeEventListener('mouseenter', this.handleLanguageSwitcherHoverEnter);
                this.languageSwitcher.removeEventListener('mouseleave', this.handleLanguageSwitcherHoverLeave);
            }

            this.darkSections.forEach((el) => {
                el.removeEventListener('mouseenter', this.handleDarkSectionHoverEnter);
                el.removeEventListener('mouseleave', this.handleDarkSectionHoverLeave);
            });

            this.jobSections.forEach((el) => {
                el.removeEventListener('mouseenter', this.handleDarkSectionHoverLeave);
                el.removeEventListener('mouseleave', this.handleDarkSectionHoverEnter);
            });

            this.ctaElements.forEach((el) => {
                el.removeEventListener('mouseenter', this.handleCtaHoverEnter);
                el.removeEventListener('mouseleave', this.handleCtaHoverLeave);
            });
        }
    },

    beforeDestroy() {
        // Remove events
        this.removeEventListeners();

        window.removeEventListener('mousemove', this.handleMouseMove);
    },
};
</script>
