<template>
    <main class="p-projects-landing" v-if="pageContent">
        <section class="simple-hero">
            <Cta :type="'title'" :sentences="titleCta" :ctaUrl="$t('nav.about.slug')"></Cta>
            <div class="simple-hero__subtitle" v-html="pageContent.fields.hero_subtitle"></div>
        </section>

        <section class="projects container bigwidth appear">
            <span class="rectangle rectangle--top-section-left"></span>
            <div class="masonry-wrapper">
                <Masonry :projects="projectsFirstHalf"></Masonry>
            </div>
        </section>
        <div class="bg-light overflow-top">
            <section class="cta-break">
                <svg class="cta-break__monogram" height="219" viewBox="0 0 552 219" width="552" xmlns="http://www.w3.org/2000/svg">
                    <g fill="none" stroke="#5a605a" transform="translate(1 1)">
                        <path
                            class="b-cta-monogram"
                            d="m60.1321586 29.2560404c5.0110132 3.0057576 9.0198238 7.414202 12.0264317 13.2253333 2.8061674 5.8111314 4.4096916 12.6241819 4.4096916 20.2387677 0 7.8149697-1.4030837 14.6280202-4.4096916 20.4391515-2.8061674 5.8111313-6.814978 10.2195758-12.0264317 13.2253334-5.0110132 3.0057575-10.8237886 4.6088287-17.438326 4.6088287-6.814978 0-12.6277533-1.6030712-17.2378855-5.0095964-4.8105727-3.4065253-8.0176211-7.8149697-10.0220264-13.2253333v17.2330101h-15.4339207v-99.9915354h15.4339207v42.8821414c1.8039648-5.4103636 5.2114537-9.8188081 10.0220264-13.2253333 4.8105727-3.4065253 10.6233481-5.009596 17.2378855-5.009596s12.2268722 1.4026869 17.438326 4.6088283zm-33.6740088 11.8226465c-3.407489 2.0038384-6.2136564 5.0095959-8.0176212 8.616505-2.0044053 3.8072929-3.0066079 8.0153536-3.0066079 12.8245657 0 5.0095959 1.0022026 9.2176565 3.0066079 13.0249495 2.0044053 3.6069091 4.6101322 6.6126666 8.0176212 8.616505s7.215859 3.0057576 11.6255506 3.0057576c6.814978 0 12.4273128-2.2042222 16.4361234-6.6126667 4.2092511-4.4084444 6.2136564-10.4199596 6.2136564-18.0345454 0-7.6145859-2.0044053-13.626101-6.2136564-18.0345455-4.2092511-4.4084444-9.6211454-6.4122828-16.4361234-6.4122828-4.4096916 0-8.2180616 1.0019192-11.6255506 3.0057576z"
                        />
                        <path
                            class="f-cta-monogram"
                            d="m226.898678 119.428768c-5.411894 4.608828-8.017621 11.421878-8.017621 20.439151v2.404606h-10.222467v13.225334h10.222467v61.317454h15.634362v-61.117071h17.237885v-13.425717h-17.237885v-1.202303c0-5.410363 1.202643-9.017273 3.607929-11.221495s7.4163-3.206141 13.429515-3.005757v-13.425717c-10.623348-.601152-19.442731 1.402686-24.654185 6.011515z"
                        />
                        <path class="line-cta-monogram" d="m429.544053 86.966586h120.063877v13.02495h-120.063877z" />
                    </g>
                </svg>
                <div class="cta-break__content container bigwidth">
                    <div class="cta-break__content__image">
                        <img src="https://bissonfortin.s3.ca-central-1.amazonaws.com/uploads/about-bandeau-travaillons-ensemble.jpg" alt="planification-de-projet-architecture" />
                        <div class="panes">
                            <div class="pane"></div>
                            <div class="pane"></div>
                        </div>
                    </div>
                    <div class="cta-break__content__text">
                        <div class="cta-break__content__text__title" v-html="$t('projects.breakCta.title')"></div>
                        <Cta :type="'outline'" :ctaText="$t('projects.breakCta.ctaText')" :ctaUrl="$t('nav.careers.slug')"></Cta>
                    </div>
                </div>
            </section>

            <section class="projects container bigwidth appear">
                <div class="masonry-wrapper">
                    <Masonry :projects="projectsSecondHalf"></Masonry>
                </div>
            </section>

            <section class="projects container bigwidth appear">
                <div class="masonry-wrapper">
                    <Masonry :projects="projectsThirdHalf"></Masonry>
                </div>
            </section>
        </div>
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import BaseView from './BaseView';
import Cta from '@/components/cta.vue';
import Masonry from '@/components/masonry.vue';

export default {
    name: 'ProjectsLanding',

    components: {
        Cta,
        Masonry,
    },

    computed: {
        ...mapGetters(['pageContent', 'isLoadingRoute']),
        titleCta() {
            return {
                [this.$t('projects.titleCta.textPart1')]: [this.$t('projects.titleCta.textPart1Reveal')],
                [this.$t('projects.titleCta.textPart2')]: 'cta',
            };
        },
        projectsFirstHalf() {
            return this.pageContent.fields.featured_projects_expanded ? this.pageContent.fields.featured_projects_expanded.slice(0, 4) : [];
        },
        projectsSecondHalf() {
            return this.pageContent.fields.featured_projects_expanded ? this.pageContent.fields.featured_projects_expanded.slice(4, 10) : [];
        },
        projectsThirdHalf() {
            return this.pageContent.fields.featured_projects_expanded ? this.pageContent.fields.featured_projects_expanded.slice(10, 14) : [];
        },
    },

    watch: {
        isLoadingRoute(newValue, oldValue) {
            this.initAnimations();
        },
    },

    methods: {
        initAnimations() {
            gsap.set('.simple-hero', {
                y: 200,
                opacity: 0,
            });

            gsap.set('.projects', {
                y: 200,
                opacity: 0,
            });

            gsap.to('.simple-hero', {
                y: 0,
                opacity: 1,
                duration: 0.7,
                delay: 0.4,
                ease: 'Power1.easeOut',
                onComplete: function() {
                    gsap.to('.title-cta, .simple-hero__subtitle', {
                        y: -150,
                        ease: 'Power1.easeOut',
                        scrollTrigger: {
                            trigger: '.simple-hero',
                            start: 'top top',
                            end: 'bottom top',
                            scrub: true,
                        },
                    });
                },
            });

            gsap.to('.projects', {
                y: 0,
                opacity: 1,
                duration: 0.7,
                delay: 0.6,
                ease: 'Power1.easeOut',
            });

            gsap.from('.cta-break__monogram', {
                y: -200,
                ease: 'Power4.easeOut',
                scrollTrigger: {
                    trigger: '.cta-break',
                    start: 'top bottom',
                    end: 'bottom bottom',
                    scrub: 0.3,
                },
            });

            const animationMono = gsap.timeline({});
            animationMono.from('.b-cta-monogram', { x: 200, opacity: 0, duration: 0.6, ease: 'Power4.easeOut' }, '+=0.4');
            animationMono.from('.f-cta-monogram', { x: 150, opacity: 0, duration: 0.6, ease: 'Power4.easeOut' }, '-=0.8');
            animationMono.from('.line-cta-monogram', { x: 150, opacity: 0, duration: 0.6, ease: 'Power4.easeOut' }, '-=0.4');

            ScrollTrigger.create({
                trigger: '.cta-break__monogram',
                animation: animationMono,
                start: 'top center',
                end: 'bottom bottom',
                onEnter: () => {
                    document.querySelector('.b-cta-monogram').classList.add('animation-path-mono-b');
                    document.querySelector('.f-cta-monogram').classList.add('animation-path-mono-f');
                    document.querySelector('.line-cta-monogram').classList.add('animation-path-mono-line');
                },
            });

            gsap.from('.cta-break__content', {
                y: 200,
                opacity: 0,
                ease: 'Power4.easeOut',
                scrollTrigger: {
                    trigger: '.cta-break',
                    start: 'bottom bottom',
                    end: 'bottom bottom',
                },
            });

            gsap.to('.panes', {
                scrollTrigger: {
                    trigger: '.cta-break',
                    start: 'top center',
                    end: 'bottom bottom',
                    onEnter: () => {
                        document.querySelector('.pane:nth-child(1)').classList.add('paneanim');
                        document.querySelector('.pane:nth-child(2)').classList.add('paneanimchild');
                    },
                },
            });

            const projects = gsap.utils.toArray('.project');
            projects.forEach((project) => {
                gsap.from(project, {
                    opacity: 0,
                    y: 80,
                    duration: 0.5,
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: project,
                        start: '-300 bottom',
                        end: 'bottom bottom',
                    },
                });
            });

            ScrollTrigger.refresh();
        },
    },

    extends: BaseView,
};
</script>
