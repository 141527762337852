<template>
    <div class="slider">
        <div v-if="quotes" class="quotes__wrapper">
            <div class="quotes">
                <div class="quote" v-for="(slide, index) in slides" :key="index" :class="{ 'quote--show': currentSlide == index }">
                    <span class="quote__tag">&laquo;</span>
                    <div class="quote__text" v-html="slide.quote"></div>
                    <span class="quote__speaker-name">{{ slide.quote_speaker_name }}</span>
                    <span class="quote__speaker-job">{{ slide.quote_speaker_job }}</span>
                </div>
            </div>
        </div>
        <span v-else class="rectangle rectangle--justundermiddle-section-left"></span>
        <div class="slider__wrapper" :class="{ slider__wrapper__quotes: quotes }">
            <div :id="id" class="cursor-cta cursor-drag">
                <div v-for="(slide, index) in slides" :key="index">
                    <img
                        v-if="slide.image"
                        :src="slide.image.url"
                        :alt="
                            slide.alt ||
                                slide.image.url
                                    .split('/')
                                    .pop()
                                    .split('.')[0]
                        "
                    />
                </div>
            </div>
        </div>
        <div class="slider__swipe">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="33" viewBox="0 0 25 33">
                <g fill="#5A605A">
                    <path
                        d="M22.313 12.04c-.533 0-1.052.173-1.48.496 0-.976-.564-1.86-1.44-2.26-.877-.4-1.903-.241-2.622.407-.354-.97-1.265-1.615-2.284-1.616-.527.002-1.04.175-1.462.495V5.597c.037-.91-.42-1.766-1.19-2.232-.77-.466-1.73-.466-2.5 0S8.11 4.688 8.146 5.597v11.08c-.247-.519-.632-.958-1.112-1.269-.707-.472-1.572-.637-2.4-.456l-1.179.258c-.26.059-.428.317-.379.583L5.3 26.37c.464 3.707 3.57 6.484 7.25 6.482h4.88c1.941.001 3.804-.782 5.177-2.176 1.373-1.394 2.144-3.286 2.143-5.258V14.516c-.002-1.366-1.092-2.473-2.438-2.475zm1.462 13.383c.002 1.709-.666 3.349-1.856 4.557-1.191 1.208-2.807 1.885-4.49 1.882H12.55c-3.198.004-5.899-2.41-6.3-5.631L4.138 16.077l.712-.148c.572-.129 1.17-.018 1.661.306.491.325.832.837.947 1.42l.263 1.27.446 2.61c.042.256.269.44.525.423.25-.026.438-.24.437-.495V5.597c.058-.774.694-1.373 1.459-1.373.764 0 1.4.599 1.458 1.373V17.49c0 .131.051.257.143.35.091.093.215.145.344.145.13.001.255-.05.346-.144.092-.093.143-.22.142-.351v-5.944c.058-.775.693-1.373 1.458-1.373s1.4.598 1.459 1.373v5.944c0 .273.218.495.487.495.13.001.254-.05.346-.144.092-.093.143-.22.142-.351v-4.954c.057-.775.693-1.373 1.458-1.373s1.4.598 1.458 1.373v4.954c0 .131.052.257.143.35.091.093.215.145.345.145.134.01.267-.039.365-.133.098-.094.153-.225.151-.362v-2.962c-.041-.555.227-1.088.696-1.379.468-.29 1.057-.29 1.526 0 .468.291.736.824.695 1.38l.025 10.894z"
                    />
                    <path
                        d="M20.604 2.962L18.65 4.946c-.09.094-.213.148-.342.148-.131 0-.257-.054-.35-.148-.186-.193-.186-.501 0-.694l1.121-1.138h-4.254c.101.18.193.364.275.554.125.3.22.612.283.93.067.326.1.658.096.99.004.132-.045.26-.135.355-.09.094-.214.148-.344.15-.13 0-.253-.053-.345-.146-.091-.093-.143-.218-.143-.35.004-.266-.023-.531-.079-.79-.05-.256-.125-.506-.225-.745-.193-.475-.476-.906-.833-1.27-.178-.183-.375-.346-.588-.486-.206-.148-.428-.271-.662-.368-.238-.113-.488-.195-.746-.246-.515-.105-1.047-.105-1.562 0-.253.049-.5.129-.734.237-.232.1-.454.223-.662.368-.207.143-.4.306-.575.487-.181.181-.342.383-.48.6-.145.21-.266.436-.362.673-.098.241-.176.49-.233.745-.051.26-.077.525-.08.791.002.132-.05.258-.14.351-.093.093-.217.145-.347.144-.27 0-.487-.222-.487-.495.01-.336.048-.67.116-.998.068-.317.166-.626.292-.923.078-.191.17-.376.275-.554H1.908L3.03 4.256c.14.141.182.354.108.54-.075.184-.252.306-.45.306-.13 0-.257-.053-.35-.148L.387 2.962c-.186-.193-.186-.502 0-.694L2.343.283c.09-.094.215-.147.345-.147.13 0 .255.053.346.147.094.09.147.216.147.347 0 .132-.053.257-.147.347L1.908 2.115h5.213l.029-.03c.22-.225.461-.427.72-.604.262-.182.541-.335.834-.457.296-.129.603-.228.917-.296.32-.068.647-.1.975-.098.331-.002.662.03.987.098.314.07.621.169.917.296.293.122.572.275.833.457.259.184.496.397.709.634l.029.03h5L17.958.99c-.186-.193-.186-.501 0-.694.091-.094.216-.148.346-.148.13 0 .255.054.346.148l1.954 1.984c.09.09.14.213.14.341 0 .128-.05.25-.14.34z"
                    />
                </g>
            </svg>
            <span>{{ $t('global.sliderDragHelper') }}</span>
        </div>
    </div>
</template>

<script>
import { tns } from 'tiny-slider/src/tiny-slider';

export default {
    name: 'Slider',

    data() {
        return {
            slider: null,
            currentSlide: 0,
        };
    },

    props: {
        id: { required: true, type: String },
        quotes: { default: false, type: Boolean },
        slides: {},
    },

    mounted() {
        if (!this.slider) {
            this.slider = tns({
                container: '#' + this.id,
                items: 2,
                mouseDrag: true,
                slideBy: 'page',
                preventScrollOnTouch: 'auto',
                controls: false,
                nav: false,
                autoWidth: true,
                gutter: 17,
                edgePadding: 0,
                responsive: {
                    0: {
                        gutter: 10,
                    },
                    767: {
                        gutter: 14,
                    },
                    1365: {
                        gutter: 20,
                    },
                },
            });
            if (this.quotes) this.slider.events.on('indexChanged', this.currentSlideChanged);
        }
    },

    methods: {
        currentSlideChanged(info) {
            this.currentSlide = info.displayIndex - 1;
        },
    },
};
</script>
