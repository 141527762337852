<template>
    <div class="route-loader" :class="{'initial-load': initialLoad}">
        <svg width="450" height="222" viewBox="0 0 440 420" xmlns="http://www.w3.org/2000/svg">
            <g fill="#101010" stroke="#101010">
                <path
                    d="m60.1321586 29.2560404c5.0110132 3.0057576 9.0198238 7.414202 12.0264317 13.2253333 2.8061674 5.8111314 4.4096916 12.6241819 4.4096916 20.2387677 0 7.8149697-1.4030837 14.6280202-4.4096916 20.4391515-2.8061674 5.8111313-6.814978 10.2195758-12.0264317 13.2253334-5.0110132 3.0057575-10.8237886 4.6088287-17.438326 4.6088287-6.814978 0-12.6277533-1.6030712-17.2378855-5.0095964-4.8105727-3.4065253-8.0176211-7.8149697-10.0220264-13.2253333v17.2330101h-15.4339207v-99.9915354h15.4339207v42.8821414c1.8039648-5.4103636 5.2114537-9.8188081 10.0220264-13.2253333 4.8105727-3.4065253 10.6233481-5.009596 17.2378855-5.009596s12.2268722 1.4026869 17.438326 4.6088283zm-33.6740088 11.8226465c-3.407489 2.0038384-6.2136564 5.0095959-8.0176212 8.616505-2.0044053 3.8072929-3.0066079 8.0153536-3.0066079 12.8245657 0 5.0095959 1.0022026 9.2176565 3.0066079 13.0249495 2.0044053 3.6069091 4.6101322 6.6126666 8.0176212 8.616505s7.215859 3.0057576 11.6255506 3.0057576c6.814978 0 12.4273128-2.2042222 16.4361234-6.6126667 4.2092511-4.4084444 6.2136564-10.4199596 6.2136564-18.0345454 0-7.6145859-2.0044053-13.626101-6.2136564-18.0345455-4.2092511-4.4084444-9.6211454-6.4122828-16.4361234-6.4122828-4.4096916 0-8.2180616 1.0019192-11.6255506 3.0057576z"
                />
                <path
                    d="m226.898678 119.428768c-5.411894 4.608828-8.017621 11.421878-8.017621 20.439151v2.404606h-10.222467v13.225334h10.222467v61.317454h15.634362v-61.117071h17.237885v-13.425717h-17.237885v-1.202303c0-5.410363 1.202643-9.017273 3.607929-11.221495s7.4163-3.206141 13.429515-3.005757v-13.425717c-10.623348-.601152-19.442731 1.402686-24.654185 6.011515z"
                />
                <path d="m429.544053 86.966586h120.063877v13.02495h-120.063877z" />
            </g>
        </svg>
    </div>
</template>

<script>
export default {
    name: 'Loader',

    props: {
        isInitialLoad: Boolean
    },

    data() {
        return {
            initialLoad: this.isInitialLoad
        }
    },

    mounted() {
        this.initialLoad = false;
    }
}
</script>
