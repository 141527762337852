import {
    gsap,
    ScrollTrigger,
    TextPlugin
} from 'gsap/all';
import { mapGetters } from 'vuex';

export default {
    name: 'BaseView',

    computed: {
        ...mapGetters([
            'isLoadingRoute',
        ]),
    },

    watch: {
        isLoadingRoute(newValue, oldValue) {
            this.initGlobalAnimations();
        },
    },

    methods: {
        initGlobalAnimations(){
            gsap.registerPlugin(TextPlugin);

            // Underline animations on CTAs
            gsap.utils.toArray('.title-cta').forEach((title, index) => {
                ScrollTrigger.create({
                    trigger: title,
                    start: 'top 90%',
                    end: 'center top',
                    once: true,
                    toggleClass: 'title-cta--in-viewport',
                });
            });
            // Text animation when hovering on title CTAs
            const titlesText = document.querySelectorAll('.title-cta .text');
            for (var i = 0; i < titlesText.length; i++) {
                let textItem = titlesText.item(i);
                if (textItem.dataset.text) {
                    textItem.parentElement.parentElement.addEventListener('mouseenter', function () {
                        gsap.to(textItem, {
                            duration: 0.3,
                            text: {
                                value: textItem.dataset.text,
                            },
                            ease: 'power3.inOut',
                        });
                    });

                    textItem.parentElement.parentElement.addEventListener('mouseleave', function () {
                        gsap.to(textItem, {
                            duration: 0.3,
                            text: {
                                value: textItem.dataset.originaltext,
                            },
                            ease: 'power3.inOut',
                        });
                    });
                }
            }

            setTimeout(() => {
                gsap.utils.toArray('.rectangle').forEach((rectangle) => {
                    // Apparition
                    gsap.set(rectangle, {
                        scaleX: 0,
                    });

                    gsap.to(rectangle, {
                        scaleX: 1,
                        duration: 1,
                        scrollTrigger: {
                            trigger: rectangle,
                            start: 'top 70%',
                            end: 'bottom bottom',
                        },
                    });

                    gsap.from(rectangle, {
                        y: 100,
                        scrollTrigger: {
                            trigger: rectangle,
                            start: 'top 70%',
                            end: '100 top',
                            scrub: true,
                        },
                    });
                });
            }, 1500);

        }
    },

    beforeDestroy() {
        const stInstances = ScrollTrigger.getAll();
        stInstances.forEach((stInstance) => {
            stInstance.kill();
        });
    },
};