<template>
    <main class="p-blank" v-if="pageContent">
        <section class="simple-hero">
            <Cta :type="'title'" :sentences="{[pageContent.title]: ''}"></Cta>
            <div class="simple-hero__subtitle" v-html="pageContent.fields.hero_subtitle"></div>
        </section>
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import BaseView from './BaseView';
import Cta from '@/components/cta.vue';

export default {
    name: 'Blank',

    components: {
        Cta,
    },

    computed: {
        ...mapGetters(['pageContent', 'isLoadingRoute']),
    },

    watch: {
        isLoadingRoute(newValue, oldValue) {
            this.initAnimations();
        },
    },

    methods: {
        initAnimations() {
            gsap.set('.simple-hero', {
                y: 200,
                opacity: 0,
            });

            gsap.to('.simple-hero', {
                y: 0,
                opacity: 1,
                duration: 0.7,
                delay: 0.4,
                ease: 'Power1.easeOut',
                onComplete: function () {
                    gsap.to('.title-cta, .simple-hero__subtitle', {
                        y: -150,
                        ease: 'Power1.easeOut',
                        scrollTrigger: {
                            trigger: '.simple-hero',
                            start: 'top top',
                            end: 'bottom top',
                            scrub: true,
                        },
                    });
                },
            });

            const animationMono = gsap.timeline({});
            animationMono.from('.b-cta-monogram', { x: 200, opacity: 0, duration: 0.6, ease: 'Power4.easeOut' }, '+=0.4');
            animationMono.from('.f-cta-monogram', { x: 150, opacity: 0, duration: 0.6, ease: 'Power4.easeOut' }, '-=0.8');
            animationMono.from('.line-cta-monogram', { x: 150, opacity: 0, duration: 0.6, ease: 'Power4.easeOut' }, '-=0.4');

            ScrollTrigger.refresh();
        },
    },

    extends: BaseView,
};
</script>
