import Vue    from 'vue';
import Sail   from './plugins/VueSail';

import App    from './App.vue';

import i18n   from './i18n';
import router from './router';
import store  from './store';


// var script = document.createElement('script');
// script.type = 'text/javascript';
// script.src = process.env.VUE_APP_SITE_BASE_URL.includes('leeroy') ? 'https://consent.cookiefirst.com/sites/bissonfortin.stage.leeroy.ca-dd076676-ee40-4034-9682-deb916f89afe/consent.js':'https://consent.cookiefirst.com/sites/bissonfortin.ca-dd076676-ee40-4034-9682-deb916f89afe/consent.js';
// document.head.appendChild(script);


Vue.config.productionTip = false;

Vue.use(Sail);

new Vue({
    Sail,
    i18n,
    router,
    store,
    render: h => h(App),
}).$mount('#app');
