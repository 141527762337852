<template>
    <main class="p-project" v-if="pageContent">
        <section class="hero cursor-cta cursor-scroll">
            <img
                v-if="pageContent.fields.img_hero"
                :src="pageContent.fields.img_hero.url"
                :alt="
                    pageContent.fields.alt ||
                        pageContent.fields.img_hero.url
                            .split('/')
                            .pop()
                            .split('.')[0]
                "
            />
        </section>

        <section class="container intro">
            <div class="textanim">
                <span class="intro__plus"
                    ><svg height="10" viewBox="0 0 10 10" width="10" xmlns="http://www.w3.org/2000/svg">
                        <g fill="none" fill-rule="evenodd" stroke="#101010">
                            <path d="m5 0v10" />
                            <path d="m10 5h-10" />
                        </g>
                    </svg>
                </span>
                <div class="intro__category">{{ pageContent.fields.category }}</div>
                <h1 class="intro__name">{{ pageContent.title }}</h1>

                <span class="intro__line"></span>
                <div class="intro__infos">
                    <div>
                        <span class="intro__infos__label">{{ $t('project.customer') }}</span
                        >{{ pageContent.fields.customer }}
                    </div>
                    <div>
                        <span class="intro__infos__label">{{ $t('project.date') }}</span
                        >{{ pageContent.fields.date }}
                    </div>
                    <div v-if="pageContent.fields.certifications" class="intro__infos__certifications">
                        <span class="intro__infos__label">{{ $t('project.certifications') }}</span>

                        <p
                            v-for="(certification, index) in pageContent.fields.certifications.slice(0, 10)"
                            :key="index"
                            class="intro__infos__certification"
                            :class="{ 'certification-hide': index > 4 && !seeMoreCertifications }"
                        >
                            {{ certification.title }}
                        </p>

                        <Cta v-if="pageContent.fields.certifications.length > 4" :type="'more'" @click.prevent.native="seeMore()" class="is-certifications"></Cta>
                    </div>
                </div>
            </div>
        </section>

        <section v-for="(block, index) in pageContent.fields.blocks" :key="index" class="container block" :class="{ fullwidth: block.matrix_element_type === 'image_fullwidth' }">
            <img
                v-if="block.matrix_element_type === 'image' && block.image"
                :src="block.image.url"
                :alt="
                    block.alt ||
                        block.image.url
                            .split('/')
                            .pop()
                            .split('.')[0]
                "
            />
            <div v-else-if="block.matrix_element_type === 'image_fullwidth' && block.image" class="image-container">
                <img
                    :src="block.image.url"
                    :alt="
                        block.alt ||
                            block.image.url
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
            </div>

            <div v-else-if="block.matrix_element_type === 'text'" class="text">
                <span class="rectangle rectangle--top-section-left"></span>
                <div class="text__content textanim" v-html="block.text"></div>
            </div>

            <div v-else-if="block.matrix_element_type === 'centeredtext'" class="text centered textanim" v-html="block.text"></div>

            <div v-else-if="block.image_left && block.image_right" class="two-cols">
                <img
                    :src="block.image_left.url"
                    :alt="
                        block.alt ||
                            block.image_left.url
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
                <img
                    :src="block.image_right.url"
                    :alt="
                        block.alt2 ||
                            block.image_right.url
                                .split('/')
                                .pop()
                                .split('.')[0]
                    "
                />
            </div>
        </section>

        <section v-if="related.length > 3" class="simple-hero related-hero">
            <h2 class="title-cta">
                {{ $t('project.relatedProjectsTitle') }}
            </h2>
            <p v-if="pageContent.categories[0]" class="simple-hero__subtitle">{{ $t(`project.relatedProjectsText.${pageContent.categories[0].slug.en}`) }}</p>
            <p v-else class="simple-hero__subtitle">{{ $t(`project.relatedProjectsText.default`) }}</p>
        </section>

        <section v-if="related.length > 3" class="projects container bigwidth appear">
            <div class="masonry-wrapper">
                <Masonry :projects="related.slice(0, 4)"></Masonry>
            </div>
        </section>

        <section class="wrapper-nextproject cursor-cta cursor-scroll">
            <div class="wrapper-nextproject__content">
                <h2 class="wrapper-nextproject__title" @click="nextpage()">{{ $t('project.nextProject') }}</h2>
                <div class="wrapper-nextproject__scrollanim"></div>
            </div>
            <div class="progress"></div>
        </section>
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import BaseView from './BaseView';
import Cta from '@/components/cta.vue';
import Masonry from '@/components/masonry.vue';
import axios from 'axios';

export default {
    name: 'Project',

    computed: {
        ...mapGetters(['pageContent', 'isDataReady', 'isChangingProject', 'isLoadingRoute', 'isLoadingProjectRoute']),
    },

    created() {},

    components: {
        Cta,
        Masonry,
    },

    data() {
        return {
            related: [],
            initialLoad: true,
            seeMoreCertifications: false,
        };
    },

    watch: {
        isDataReady(newValue, oldValue) {
            if (newValue && this.initialLoad) {
                this.$store.commit('SET_NEXT_PROJECT_IMAGE_URL', this.pageContent.nextProject.fields.img_hero.url);
                this.initialLoad = false;
                this.getRelatedProjects();
            }
        },

        isLoadingRoute(newValue, oldValue) {
            if (!newValue) this.initAnimations();
        },

        isChangingProject(newValue, oldValue) {
            if (!newValue) this.initAnimations();
        },

        isLoadingProjectRoute(newValue, oldValue) {
            if (!newValue) {
                this.$store.commit('SET_NEXT_PROJECT_IMAGE_URL', this.pageContent.nextProject.fields.img_hero.url);
                this.$store.commit('SET_PROJECT_TRANSITION', false);
            }
        },
    },

    methods: {
        seeMore() {
            this.seeMoreCertifications = !this.seeMoreCertifications;
        },

        getRelatedProjects() {
            axios
                .get(`${process.env.VUE_APP_SITE_BASE_URL}/api/get-similar?locale=${this.$i18n.locale}&content_id=${this.pageContent.id}`)
                .then((res) => {
                    const result = JSON.parse(res.data.message);
                    this.related = result.projects;
                    this.shuffle(result.projects);
                })
                .then(() => {
                    setTimeout(() => {
                        ScrollTrigger.refresh(true);
                    }, 1000);
                })
                .catch((err) => {
                    this.related = [];
                });
        },

        shuffle(array) {
            let currentIndex = array.length;
            while (currentIndex != 0) {
                let randomIndex = Math.floor(Math.random() * currentIndex);
                currentIndex--;
                [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
            }
        },

        nextpage() {
            if (this.timeout) clearTimeout(this.timeout);

            // Sort of debounce the next page event
            this.timeout = setTimeout(() => {
                window.location.href = this.pageContent.nextProject.slug;
                clearTimeout(this.timeout);
            }, 100);
        },

        initAnimations() {
            const stInstances = ScrollTrigger.getAll();
            stInstances.forEach((stInstance) => {
                stInstance.kill();
            });

            ScrollTrigger.refresh();

            const containers = gsap.utils.toArray('.container');
            containers.forEach((container) => {
                gsap.from(container, {
                    y: 200,
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: container,
                        start: 'top bottom',
                        end: 'bottom +50',
                    },
                });

                gsap.from(container, {
                    opacity: 0,
                    duration: 0.6,
                    scrollTrigger: {
                        trigger: container,
                        start: 'top bottom',
                        end: 'bottom bottom',
                    },
                });
            });

            const texts = gsap.utils.toArray('.textanim');
            texts.forEach((text) => {
                gsap.from(text, {
                    y: 200,
                    opacity: 0,
                    duration: 0.6,
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: text,
                        start: 'top bottom',
                        end: 'bottom bottom',
                    },
                });
            });

            gsap.to('.progress', {
                scaleX: 1,
                scrollTrigger: {
                    trigger: '.wrapper-nextproject',
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: 'top bottom',
                    end: 'bottom bottom',
                },
            });

            gsap.to('.wrapper-nextproject', {
                opacity: 1,
                scrollTrigger: {
                    trigger: '.wrapper-nextproject',
                    invalidateOnRefresh: true,
                    scrub: true,
                    start: 'top bottom',
                    end: 'bottom bottom',
                    onLeave: () => this.nextpage(),
                },
            });

            if (document.querySelector('.image-container img')) {
                gsap.to('.image-container img', {
                    y: -150,
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: '.image-container',
                        start: 'top bottom',
                        end: 'bottom bottom',
                        scrub: true,
                    },
                });
            }

            ScrollTrigger.refresh();
        },
    },

    extends: BaseView,
};
</script>
