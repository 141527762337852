<template>
    <main class="p-home" v-if="pageContent">
        <section class="hero">
            <div class="panes">
                <div class="pane"></div>
                <div class="pane"></div>
                <div class="pane"></div>
            </div>
            <img
                v-if="pageContent.fields.hero_image"
                :src="pageContent.fields.hero_image.url"
                :alt="
                    pageContent.fields.alt ||
                        pageContent.fields.hero_image.url
                            .split('/')
                            .pop()
                            .split('.')[0]
                "
            />

            <div class="titre-subtitle">
                <h1 class="hero__title">{{ pageContent.fields.hero_title }}</h1>
                <div class="hero__subtitle" v-html="pageContent.fields.hero_subtitle"></div>
            </div>
            <div class="hero__discover-cta">{{ $t('home.heroDiscoverCta') }}</div>
        </section>

        <section class="container container-section">
            <span class="rectangle rectangle--title-right"></span>
            <Cta :type="'title'" :sentences="titleCta1" :breakSentences="true" :ctaUrl="$t('nav.about.slug')"></Cta>
            <div class="subtitle" v-html="pageContent.fields.vision_block_subtitle"></div>
            <Slider v-if="pageContent.fields.vision_block_slider" :id="'vision-slider'" :slides="pageContent.fields.vision_block_slider"></Slider>
        </section>

        <section class="projects container bigwidth container-section">
            <span class="rectangle rectangle--title-right"></span>
            <Cta :type="'title'" :sentences="titleCta2" :breakSentences="true" :ctaUrl="$t('nav.projects.slug')"></Cta>
            <div class="masonry-wrapper">
                <span class="rectangle rectangle--justundermiddle-section-right rectangle--hidden-tablet"></span>
                <Masonry :projects="pageContent.fields.featured_projects_expanded"></Masonry>
            </div>
        </section>

        <section class="container-section">
            <div class="container offset-tablet extra-mb">
                <span class="rectangle rectangle--title-left"></span>
                <Cta :type="'title'" :sentences="titleCta3" :ctaUrl="$t('nav.careers.slug')"></Cta>
            </div>
            <div class="container bigwidth norpadding">
                <Slider v-if="pageContent.fields.team_block_slider" :id="'team-slider'" :quotes="true" :slides="pageContent.fields.team_block_slider"></Slider>
            </div>
        </section>
    </main>
</template>

<script>
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import BaseView from './BaseView';
import Cta from '@/components/cta.vue';
import Slider from '@/components/slider.vue';
import Masonry from '@/components/masonry.vue';

export default {
    name: 'Home',

    components: {
        Cta,
        Slider,
        Masonry,
    },

    computed: {
        ...mapGetters(['pageContent', 'isLoadingRoute']),
        titleCta1() {
            return {
                [this.$t('home.titleCta1.textPart1')]: [this.$t('home.titleCta1.textPart1Reveal')],
                [this.$t('home.titleCta1.textPart2')]: 'cta',
            };
        },
        titleCta2() {
            return {
                [this.$t('home.titleCta2.textPart1')]: [this.$t('home.titleCta2.textPart1Reveal')],
                [this.$t('home.titleCta2.textPart2')]: 'cta',
            };
        },
        titleCta3() {
            return {
                [this.$t('home.titleCta3.textPart1')]: [this.$t('home.titleCta3.textPart1Reveal')],
                [this.$t('home.titleCta3.textPart2')]: 'cta',
            };
        },
    },

    watch: {
        isLoadingRoute(newValue, oldValue) {
            this.initAnimations();
        },
    },

    methods: {
        initAnimations() {
            gsap.set('.titre-subtitle .hero__title', {
                y: 200,
                opacity: 0,
            });

            gsap.set('.titre-subtitle .hero__subtitle', {
                y: 300,
                opacity: 0,
            });

            gsap.to('.hero__title', {
                y: 0,
                opacity: 1,
                duration: 0.7,
                delay: 0.7,
                ease: 'Power3.easeOut',
                onComplete: function() {
                    gsap.to('.titre-subtitle', {
                        y: -300,
                        ease: 'Power1.easeOut',
                        scrollTrigger: {
                            trigger: '.hero',
                            start: 'top top',
                            end: 'bottom top',
                            scrub: true,
                        },
                    });
                },
            });

            gsap.to('.hero__subtitle', {
                y: 0,
                opacity: 1,
                duration: 0.7,
                delay: 0.7,
            });

            const sections = gsap.utils.toArray('.container-section');
            sections.forEach((target) => {
                gsap.from(target, {
                    y: 200,
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: target,
                        start: 'top bottom',
                        end: 'bottom bottom',
                        scrub: true,
                    },
                });

                gsap.from(target, {
                    opacity: 0,
                    duration: 2,
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: target,
                        start: 'top bottom',
                        end: 'bottom bottom',
                    },
                });
            });

            const sectionsTitles = gsap.utils.toArray('.p-home section h2');
            sectionsTitles.forEach((targettext) => {
                gsap.from(targettext, {
                    y: 100,
                    opacity: 0,
                    duration: 0.7,
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: targettext,
                        start: 'top bottom',
                        end: 'bottom bottom',
                    },
                });
            });

            const sectionsSubtitles = gsap.utils.toArray('.p-home section .subtitle');
            sectionsSubtitles.forEach((targettext) => {
                gsap.from(targettext, {
                    y: 100,
                    opacity: 0,
                    duration: 0.7,
                    ease: 'Power1.easeOut',
                    scrollTrigger: {
                        trigger: targettext,
                        start: 'top bottom',
                        end: 'bottom bottom',
                    },
                });
            });

            gsap.from('.grid', {
                y: 200,
                opacity: 0,
                duration: 0.7,
                ease: 'Power1.easeOut',
                scrollTrigger: {
                    trigger: '.grid',
                    start: 'top bottom',
                    end: 'bottom bottom',
                },
            });

            gsap.from('.norpadding', {
                y: 200,
                opacity: 0,
                duration: 0.7,
                ease: 'Power1.easeOut',
                scrollTrigger: {
                    trigger: '.norpadding',
                    start: 'top bottom',
                    end: 'bottom bottom',
                },
            });

            ScrollTrigger.refresh();
        },
    },

    extends: BaseView,
};
</script>
