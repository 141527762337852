/**
 * Sail.js
 */

import axios from 'axios';

const PAGES_TYPE = 'pages';

class VueSail
{
    constructor()
    {
        if (!process.env.VUE_APP_SITE_BASE_URL) {
            // eslint-disable-next-line no-console
            console.error("Error: Please set VUE_APP_SITE_BASE_URL in .env");
        }
    }

    /**
     *
     * Gets any post type by slug, and defaults to pages
     *
     * @param slug
     * @param type
     *
     * @return object
     *
     */
    getContentBySlug(slug, type = PAGES_TYPE)
    {
        let response;

        axios
            .get(`${process.env.VUE_APP_SITE_BASE_URL}/api/content/${type}/${slug}`)
            .then(res => {
                response = res;
            })
            .catch(err => {
                response = {
                    code:    500,
                    message: "An error occured",
                    page:    null
                }
            });

        return response;
    }
}

export default {
    install(Vue, options)
    {
        Vue.prototype.$sail = new VueSail();
    }
}
